import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/halloween-2018-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 2018 slasher movie, Halloween"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 20</h1>
                    <h2>Halloween (2018)</h2>
                    <h3>October 2, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/halloween-2018">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:03.177)
You&#39;re listening to Bring Me the Axe. I&#39;m Brian White, one half of this morbid equation, and I&#39;m joined by my co-host and actual brother Dave White. Dave, how you doing?</p>
<p>Dave! (00:12.022)
Thank you for asking. I am doing OK. I&#39;ve been trying to decorate my apartment for Halloween and I&#39;ve been running into some trouble because everything I see, I think to myself, well, I&#39;d put that on a shelf and I&#39;d just leave it there for the rest of the year. Like I bought this really great, very campy bust of Frankenstein&#39;s monster and his eyes light up. And and I got it at one of Michael&#39;s craft store. I&#39;m fine establishment.</p>
<p>Bryan! (00:18.442)
Hmm.</p>
<p>Bryan! (00:34.81)
Oh, sweet.</p>
<p>Dave! (00:41.306)
And I put it on the shelf and I thought, I&#39;m just gonna leave that there. It&#39;s, it looks great.</p>
<p>Bryan! (00:46.093)
Yeah. Yeah. I&#39;m I&#39;m I&#39;m of a mind. I like the thing is is like it&#39;s always Halloween for me. So I would always I would do the same thing like I did the same a couple of years ago. Went to Michael&#39;s. They&#39;ve got some pretty cool stuff and I basically just bought ship that I put on my desk to kind of you know have like skulls and <em>**</em> laying around because II you know I be like that.</p>
<p>Dave! (01:10.538)
I think what that says about me is that I have the taste of a child.</p>
<p>Bryan! (01:14.953)
Yeah, yeah, no, I get it. And that&#39;s all right. You know, just do what you want. Do whatever you want. Yeah, no, I get you because I like I was just killing time the other day I had a feeling about an hour to kill and I went to spirit Halloween and just walked around taking it all in. And I was like, I&#39;m gonna buy that I&#39;ll buy that I&#39;m gonna get that</p>
<p>Dave! (01:18.83)
Yeah, that&#39;s my fucking house.</p>
<p>Bryan! (01:40.225)
Killer Clown&#39;s gun, I&#39;m gonna get the Freddy Krueger glove, I&#39;m gonna get this and this, and then I left with a Camp Crystal Lake coffee mug.</p>
<p>Dave! (01:49.555)
I mean, that&#39;s reasonable, though.</p>
<p>Bryan! (01:53.537)
That place just gets me though. So we practically grew up in neighborhood video stores and the steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies than Dave. Just before we get into it, here&#39;s a little housekeeping. If you wanna keep up with us between episodes, you can find us on socials, my Instagram mostly. I&#39;d say that&#39;s the one that I&#39;m using most. We&#39;re having a lot of fun. You can catch us at</p>
<p>Bring Me the Axe, Bring Me the Axe pod, I believe is where I&#39;m at. And Dave&#39;s at That Queer Wolf. And we&#39;re having a good time. So you can join us there. Follow us. We want to really kind of bulk up.</p>
<p>Dave! (02:34.538)
Yeah, we&#39;re doing a thing in October.</p>
<p>Bryan! (02:36.893)
Yes, we are. It&#39;s gonna be a lot of fun and you&#39;re gonna miss it if you&#39;re not subscribed.</p>
<p>Dave! (02:40.383)
You gotta subscribe to find out what the thing is.</p>
<p>Bryan! (02:42.909)
Yes, I want to leave him in suspense. We also got a sweet website now at bring me the axe.com. You can listen to all our past shows there. Read the transcripts. You can also contact us directly at bring me the axe pod at gmail.com with any questions, comments or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts.</p>
<p>be doing us a favor by leaving us a five star review on Apple podcasts. You can also do it on Spotify now too. I we would appreciate it if you did that because it does help us out raises the raises the visibility kind of you know, makes us more likely to be recommended to people who are also into horror movies. So get on it.</p>
<p>Dave! (03:24.682)
Yeah, it&#39;s called gaming the system. That&#39;s how you get by these days.</p>
<p>Bryan! (03:26.697)
Yeah, that&#39;s right, man. Gotta you gotta let him know you&#39;re out there. So just want to get all that out of the way at the top of the show. So we&#39;re gonna give you a little taste of what&#39;s coming next. So here&#39;s we are gonna disagree on this one. Here&#39;s a taste.</p>
<p>Dave! (03:39.21)
Yeah, speaking of trashy garbage...</p>
<p>Bryan! (06:29.897)
There it is. Halloween 2018. So you&#39;re not a fan.</p>
<p>Dave! (06:36.515)
I, you know, I wanted to like this. Well, first of all, let me say, if ever there was a movie made by straight white dudes, it is this movie. And that becomes more evident in the ones that follow. Um, I wanted to enjoy it, and I just don&#39;t. It just it feels like they don&#39;t really know, like they didn&#39;t.</p>
<p>they&#39;re sort of making a remake, but they didn&#39;t wanna make a remake, and so they&#39;re kind of stuck somewhere in between. So what it feels like is a kind of re-imagining that is just constantly referencing the other movies, like to the point that it&#39;s tiresome.</p>
<p>Bryan! (07:23.265)
I&#39;m pretty charitable on this one. I don&#39;t think it&#39;s great. I think it&#39;s good.</p>
<p>Dave! (07:28.402)
This is the only one I&#39;ve made it through completely. I made it through half of the one after this and was like, nah, fuck this movie. And then the last one sounds like absolute garbage.</p>
<p>Bryan! (07:38.609)
I take a different, I take a sort of I like the last one. I think the second one is really silly. They but yeah, this is a that last movie. I think most people really dislike it and I think that I was able to get through it because I think I watched a different movie than most people did because where this movie ends, the sequels kind of</p>
<p>Dave! (07:46.407)
Oh, it&#39;s fucking ridiculous.</p>
<p>Bryan! (08:07.101)
And it becomes less about Laurie and Michael and sort of more about the kind of weird effect that Haddonfield has on the people there and how it&#39;s sort of a place that seems to create monsters.</p>
<p>Dave! (08:20.462)
I think that&#39;s where they go wrong. I think that&#39;s the idea that they&#39;re really doing a thing in this. Like they&#39;re really this social commentary in this is like it is so fucking ham-fisted and absurd that it&#39;s hard to take any of it seriously. So, oh, you don&#39;t when this movie came out, they sold this basically as like it was Halloween for the Me Too movement. Like it was supposed to be this, you know, Halloween the feminist version. It&#39;s like this is this is like</p>
<p>Bryan! (08:35.985)
Oh, I don&#39;t see any social commentary in this movie.</p>
<p>Dave! (08:49.718)
the least feminist thing I can imagine. These are the most one-dimensional characters.</p>
<p>Bryan! (08:52.193)
Oh, well, I mean, if they did that, I mean, if they did that, it probably had something to do with the fact that the Weinstein company had control of this. You know, and they were trying to put a little distance between them. I remember I remember none of that.</p>
<p>Dave! (08:59.362)
That might&#39;ve been part of it.</p>
<p>Dave! (09:04.63)
But like the idea that it&#39;s like these, it&#39;s strong, you know, it&#39;s these strong female characters that I&#39;m watching and thinking, are they? I mean, you make one of them look like a lunatic.</p>
<p>Bryan! (09:12.149)
Well, well, I don&#39;t I don&#39;t remember any of that, because also at the same time, I was extremely skeptical of this movie when it came out. And I only saw it just this year for the first time. Because like I</p>
<p>Dave! (09:24.534)
I saw it like last year, I think, because I was... Or it was like during the pandemic, I watched it because I had seen literally everything else.</p>
<p>Bryan! (09:30.261)
Yeah, like as far as far as Halloween goes, like the first one is one of my unsurprised surprising to no one. The first one&#39;s like my one of my favorite movies of all time. It&#39;s it&#39;s a it&#39;s a perfect movie. You know, we&#39;re going to talk about it. But the sequels, I think the sequels I saw first, and for a really long time, they put me off to Halloween as a brand, because they&#39;re so bad.</p>
<p>Dave! (09:39.958)
Yeah, it&#39;s one of the greatest horror movies ever made.</p>
<p>Bryan! (09:57.853)
and I have no I like and I understand that they&#39;ve got their fans but I don&#39;t understand those people at all. Like I can&#39;t understand a single person who watched Halloween five or uh H2O or Resurrection and it&#39;s just like that.</p>
<p>Dave! (10:10.87)
H2O is actually a pretty good one. Resurrection is absolutely awful. It is worth watching for different reasons. But I think here&#39;s the problem is what you&#39;re saying is the problem with this movie is that the first one is a thoroughly solid movie. Like, I mean, I don&#39;t know if I&#39;d call it perfect, but I think it&#39;s really fucking good. And it&#39;s one of my favorite movies. And it&#39;s a very serious, it&#39;s a very simple premise. And I don&#39;t wanna get too much into it because we&#39;re not talking about that movie, but the whole point of Halloween</p>
<p>Bryan! (10:29.209)
It&#39;s really close.</p>
<p>Dave! (10:40.786)
And this gets lost now in this kind of post final girl, post men, women chainsaws conversations. The whole point of Halloween was the idea that this kind of nameless faceless evil could show up anywhere for no reason and kill you. For no reason. And there is very little you can do to stop it. That this kind of thing, and this, it all comes out of his having grown up in Kentucky and witnessing like virulent racism that he just could not fathom.</p>
<p>Bryan! (11:09.365)
Mm hmm.</p>
<p>Dave! (11:11.026)
And so this is a very simple premise. It&#39;s something that resonates today, just as much as it did in the seventies, because it is a basic fear that you have of like the outside world. This movie seems to be wanting to take that, but then build out a story and give it all this context. And when you do that, you undermine the original premise, the thing that makes the movie relatable, the thing that makes it so interesting and so good. The other direction they could have gone with it, which they...</p>
<p>I tried not to because I get the feeling they&#39;re trying to do like a kind of a prestige horror thing here. You could have leaned into the other side of horror, which is the kind of sillier, campier parts of it. The reason why people like four, five, six, you know, H2O because they&#39;re goofy, dumb horror movies. They&#39;re dumb teen movies. There&#39;s nothing really serious about them. There&#39;s not a lot of stakes because you don&#39;t really like anybody. They don&#39;t go in either direction. I think they think they&#39;re going in the first direction, but you&#39;ve sort of ruined the first direction.</p>
<p>And I&#39;m kind of left with nothing.</p>
<p>Bryan! (12:09.677)
because I really actually I like the premise because it&#39;s this is probably and as far as I know this is the only movie that sort of takes the whole final girl concept and kind of extrapolates it out to its logical conclusion because like there are movies where the you know the final girl comes back like</p>
<p>the screams do this, but like every movie following the first one in which like a massacre happens. And like there&#39;s a soul survivor who goes through hell. In the sequel, they come back and it&#39;s like maybe they have nightmares or something like that. But like, this is probably a much more reasonable look at somebody who&#39;s basically been looking over their shoulder their whole life following</p>
<p>Dave! (12:54.798)
I think it&#39;s a shallow look at it though, because it&#39;s like they&#39;re sitting around going, hey, I bet that would make someone real crazy, right? And the other one&#39;s like, yeah, of course it would. Hey, that&#39;s a great premise. And that&#39;s about as far as it goes. Like that was that final girl support club or whatever the Grady Hendrix book, one of the more recent ones. That&#39;s a little bit deeper dive. I mean, that&#39;s also kind of a campier story, but it&#39;s this idea of like, I bet that would drive a person crazy. It&#39;s like, that&#39;s as far as you go. There&#39;s no nuance to it. There&#39;s no sort of depth.</p>
<p>Bryan! (13:06.662)
Hmm.</p>
<p>Bryan! (13:11.755)
And yeah.</p>
<p>Dave! (13:24.502)
Like, what does that actually mean?</p>
<p>Bryan! (13:24.901)
Oh, no, because this is a very wobbly script. Like there&#39;s a million things.</p>
<p>Dave! (13:29.682)
Oh fuck yes, because it was hacked to pieces a million times between like 2005 and 2018.</p>
<p>Bryan! (13:37.277)
I believe that this was entirely Danny McBride&#39;s story. Cause</p>
<p>Dave! (13:42.858)
And I feel like that might like that&#39;s the other I&#39;m like, these are not horror guys. And this is a thing we run into all the time where it&#39;s like, here&#39;s a bunch of people who just kind of want to make a movie so they go with horror. Now, this is not quite that. But this is a movie where it&#39;s like you might like horror, but it has like a hot topic. It&#39;s like it&#39;s real basic.</p>
<p>Bryan! (14:01.201)
Yeah, it&#39;s you know what I think it is also is I think a bit of it is the bloom house effect where it&#39;s just there&#39;s not a lot of room for uh for the sort of for depth it&#39;s just this is the friday night at the movies crowd you know it&#39;s</p>
<p>Dave! (14:06.958)
Yes, 100%.</p>
<p>Dave! (14:16.618)
And that&#39;s what I mean is like you didn&#39;t go for a nuanced portrayal or an actual sort of analysis of something, nor did you go for the campier fun side. You tried to swung for this 2018, 2020 prestige horror bullshit and you missed big time. You know what they didn&#39;t miss on? They didn&#39;t miss on the fucking soundtrack.</p>
<p>Bryan! (14:36.329)
I don&#39;t get that. I don&#39;t.</p>
<p>Oh no. No. So let&#39;s do some notes here. Yeah. Oh, also as a, as a, you know, a warning before we get rolling, we&#39;re going to talk about this movie from beginning to end. So spoilers to follow, you know, get hip, see the movie. It&#39;s, you know, he&#39;s shitting on it. I like it. You know, follow your heart. So the year is 2018. So some other movies. Also, that&#39;s really wild for us because we have not done anything nearly this recent. Like our bread and butter.</p>
<p>Dave! (15:07.65)
Yeah, and do not get used to it.</p>
<p>Bryan! (15:09.657)
Bread and butter is really like the 1980s. And so.</p>
<p>Dave! (15:13.11)
Cause I don&#39;t like shit like this. I don&#39;t think it&#39;s fun. I don&#39;t think it&#39;s interesting. And I don&#39;t usually think it&#39;s very good.</p>
<p>Bryan! (15:19.177)
I think maybe that&#39;s the problem with is your</p>
<p>Dave! (15:21.214)
Oh yeah, no, I went into this pre-disposed to disliking, and I understand that.</p>
<p>Bryan! (15:25.965)
because well because I did too and I&#39;ve done that a bunch on some real like recent movies like I went in to talk to me expecting to hate that movie and I came out the other side surprised like crazy like that movie had so much running against it as far as I&#39;m concerned.</p>
<p>Dave! (15:41.462)
But the difference is that Talk To Me is a original concept. This is an IP, and there were some million ways they could have gone wrong, and there was like a fucking deck of stacked against them.</p>
<p>Bryan! (15:48.749)
I&#39;m not as dismissive of modern horror as as you are. But yeah, but this is just this is real, real interesting because like we just have not done anything even remotely this recent. So other movies released that year were Mandy, which is a movie I love the <em>**</em> out of.</p>
<p>Dave! (16:11.934)
Ooh, yeah. I thought that was pretty great.</p>
<p>Bryan! (16:14.989)
That&#39;s a fucking great movie. Also that year, the Suspiria remake. I only remember the ending.</p>
<p>Dave! (16:19.094)
Meh.</p>
<p>Dave! (16:23.51)
It is a remake done right. I mean, it is slow and I did not love it, but it is a reimagining that is very well done.</p>
<p>Bryan! (16:32.529)
Yep. Uh on the other hand, there was the none which I tried which I tried to watch and I couldn&#39;t finish it which is which does not often happen to me. It it</p>
<p>Dave! (16:36.302)
It&#39;s not good.</p>
<p>Dave! (16:42.102)
Yeah, because it has it has the story that is the length of the story is about that of a television program, a half hour, but stretched out to two hours.</p>
<p>Bryan! (16:53.89)
the movie. Yeah. Uh also out that year. I have not seen Cloverfield Lane which is that it yeah and uh Mary another movie that they picked</p>
<p>Dave! (17:01.71)
I don&#39;t think I saw that. That&#39;s John Goodman.</p>
<p>Dave! (17:07.086)
Good cast.</p>
<p>Dave! (17:11.506)
Yeah, here&#39;s the fucking problem with it. This could have been something else. Like I didn&#39;t, I probably didn&#39;t want to see it because I was like Cloverfield, that fucking Godzilla ripoff that wasn&#39;t very good. Yeah, why am I going to watch a sequel?</p>
<p>Bryan! (17:20.145)
Yeah, yeah, I stayed away. I stayed away from it for a really long time. And then I watched No One Will Save You. And I fucking hated every goddamn second of it. And I was like, you know what, I&#39;m gonna watch a movie about like an alien invasion that I like, or that I&#39;m likely to enjoy more than this. And I watched that. And I don&#39;t know, maybe it&#39;s just like, but the bias of coming off No One Will Save You. But I liked it a lot.</p>
<p>Dave! (17:46.518)
You know what? You know what they should have called it? They should have called it some lady trapped in a bunker with John Goodman. I would have watched that movie.</p>
<p>Bryan! (17:50.829)
I&#39;ve watched that even if it was literally just some lady trapped in a bunker with John Goodman. Yeah, cuz he seems like a nice guy. Uh and lastly, Hereditary. Uh you know what? As far as the whole Ari Aster thing goes, I&#39;m that one doesn&#39;t strike me as odious as some of his other movies where that dude is just so <strong><strong> far up his </strong></strong> that he will never be</p>
<p>Dave! (17:56.278)
Yeah, just like the two of them just talking.</p>
<p>Dave! (18:03.882)
Boo! Talk about a movie I fucking hate.</p>
<p>Bryan! (18:20.83)
but yeah 2018 not a bad year for horror there&#39;s a couple of good ones so cast and crew the director of this movie is David Gordon Green who prior to this movie was a comedy guy that routinely worked with Danny McBride on like stone</p>
<p>Dave! (18:33.258)
And I&#39;ll tell you what, I like his comedy a lot. Eastbound to Down, Vice Principals, fucking hilarious shows, great stuff. Should have stuck to that.</p>
<p>Bryan! (18:36.779)
I do too. Yeah, yeah, it&#39;s</p>
<p>Bryan! (18:41.297)
have you have you else have you watched the righteous gemstones speaking of John Goodman it&#39;s hilarious lots of penises so yeah so like he mentioned he worked on stuff like he&#39;s bounding down vice principles which is super fucking funny very dark yeah and somehow he ended up tied to the horror genre directed all three of the modern Halloween&#39;s</p>
<p>Dave! (18:45.197)
I have not.</p>
<p>Dave! (18:59.367)
and incredibly dark.</p>
<p>Dave! (19:07.798)
Something tells me that Danny McBride had a lot to do with it.</p>
<p>Bryan! (19:10.981)
probably like he&#39;s that he&#39;s riding. He&#39;s riding high. And so now he ended up with the two exorcist movies, which honestly don&#39;t look all that great to me. As mentioned, the writer is Danny McBride, who&#39;s also well known comedy guy and one of my favorites. Still kind of wild to me that comedy creatives ended up at the helm of a horror movie production. I mean, I can&#39;t really think of another time that that&#39;s happened.</p>
<p>Dave! (19:25.47)
Might know him from his curly hair on the television.</p>
<p>Dave! (19:36.674)
This strikes me as someone was like, hey, we&#39;ve got this, they&#39;re like, what do you guys want to do? We&#39;ve got a three picture deal with you. What do you want to do? And they were like, Halloween. They were like, okay, I guess. We were thinking something funny, but sure, it&#39;s up to you, I think.</p>
<p>Bryan! (19:48.301)
I&#39;m going to have to do that. Yeah. Uh the original director on this was supposed to be Adam Wingard but I think he got I think he got snatched up to do his Godzilla movie around the same time. I might have to I might have to II like II. You know what? The sequel and the one after that, the one with King Kong, I thought were pretty great. Yeah.</p>
<p>Dave! (19:55.523)
Ehh</p>
<p>Dave! (20:02.178)
Which was okay.</p>
<p>Dave! (20:10.026)
Yeah, I like those two. And I am a big fan of a Gojira.</p>
<p>Bryan! (20:14.089)
Oh, yeah. Oh, yeah. Yeah. I understand. So, there&#39;s a bit of comedy in the lighter moments of this movie for the most part. I don&#39;t feel like they work in the context of the film. The parts of the script that do work tell me that he&#39;s definitely a fan because in spite of the hiccups, I think that I think that particularly in the final third of the movie, like they do have an understanding of like what makes</p>
<p>at least Halloween in particular work.</p>
<p>Dave! (20:46.258)
I think they&#39;re trying to do way too much fan service in this.</p>
<p>Bryan! (20:49.069)
Oh, sure. Yeah. I mean, there&#39;s direct like sort of like juxtapositions. Yeah, I don&#39;t know if I mean that <em>**</em> was deliberate and I don&#39;t know if it was fan service so much as they were trying to make a point.</p>
<p>Dave! (20:53.054)
Oh, there&#39;s shot for shot rip-offs.</p>
<p>Dave! (21:01.894)
I actually think the scene you&#39;re thinking of and the one I&#39;m kind of referencing, I think that scene is actually really cool.</p>
<p>Bryan! (21:09.599)
the balcony.</p>
<p>Dave! (21:10.994)
Oh no, I was thinking of the school.</p>
<p>Bryan! (21:14.059)
school scene.</p>
<p>Dave! (21:16.15)
Well, we&#39;ll get there. She&#39;s sitting in the classroom.</p>
<p>Bryan! (21:17.037)
Alright, yeah, we&#39;ll get there. Oh, yeah. So, the cast, obviously, Oscar winner, Jamie Lee Curtis returning to returning to Laurie Strode for the first time since Halloween Resurrection in 2002. Uh she seems to be having a really great time right now. It&#39;s to have fun, winning major rewards, playing Madame Leota and I&#39;m glad to see it quite frankly. I absolutely <em>**</em> adore her.</p>
<p>Dave! (21:24.514)
Heard of her?</p>
<p>Dave! (21:43.57)
I do too, she seems like someone who&#39;s kind of exhausting though as a person. Like, like I think I love that you are out there in the world, Jamieleigh Curtis. I love what you do. I think you deserve all the accolades and all the praise, but I don&#39;t think I would want to hang out with you.</p>
<p>Bryan! (21:59.881)
I don&#39;t know if I would want to hang out with her either but I she&#39;s got a really kind of like sunny personality and it&#39;s one of those things that I she seems quite genuine. Yes. So, James Jude Courtney and Nick Castle portray the</p>
<p>Dave! (22:11.038)
And she is married to comedy royalty.</p>
<p>Dave! (22:21.482)
Yeah, something tells me Nick Castle&#39;s not doing all the, uh, tumbles and falls and... He&#39;s doing more of the standing.</p>
<p>Bryan! (22:24.389)
No, no, no. He he actually he plays. He actually appears in one scene and that&#39;s it and that&#39;s the scene where she tries to shoot him through the window.</p>
<p>And so, um, one of the, uh, one of the things that I read about this is in development, Green specifically told Courtney that he wanted the shape to move like a cat. And given how he does move in this movie, I&#39;d say mission accomplished. He&#39;s catlike. They&#39;re uh, they&#39;re nimble.</p>
<p>Dave! (22:54.801)
You think it&#39;s cat-like? I don&#39;t know much about cats.</p>
<p>Dave! (23:00.83)
I bet Nick Carousel does not move like a cat.</p>
<p>Bryan! (23:02.893)
that. No, he was probably pleased. Pleased not to have to not to have to do that but uh yeah.</p>
<p>Dave! (23:06.482)
At 72 years old, he does not move like a cat.</p>
<p>Dave! (23:12.726)
You know who my favorite Michael Myers is? Dick Warlock. Because what a fucking name.</p>
<p>Bryan! (23:16.577)
Uh he&#39;s part two, right? Dick Warlock. Yeah, I agree. I agree. Yeah. Rounding out the cast is Judy Greer as Laurie&#39;s daughter Karen.</p>
<p>Dave! (23:23.01)
Jesus, that&#39;s the coolest name ever.</p>
<p>Dave! (23:32.418)
who I love, I think as every gay, every gay man is obligated to love Judy Greer. And so I do, but I will tell you, I cannot hear her voice without thinking of Cheryl Carroll from Archer. It&#39;s all I hear. You&#39;re not my boss.</p>
<p>Bryan! (23:35.909)
I think she is... Ha ha ha!</p>
<p>Bryan! (23:48.429)
I know when I see it, I can only think of her as Kitty who George Bluth&#39;s receptionist at rest of development. Say goodbye to these Michael. Yep. So here&#39;s some notes. John Carpenter was a producer on this movie who had some say in the production. Usually this would be just like a marketing move to get his name on the poster or something that sort of thing carries weight with fans. Allegedly.</p>
<p>Dave! (23:54.334)
Yes, that was that&#39;s the only say goodbye to these.</p>
<p>Bryan! (24:18.013)
you know, but he actually had a say in how the movie ended up. More importantly, however, the soundtrack is by him and his son, and it is a fucking killer. That&#39;s right. Daniel Davies is also a big a big part.</p>
<p>Dave! (24:27.954)
Hey, don&#39;t leave out Daniel Davies. It is the three of them and holy shit, these guys. I feel like, I think probably Daniel Davies more than his son, he sort of like reinvigorates him sort of the same way that Alan Howarth did.</p>
<p>Bryan! (24:43.625)
that&#39;s what I was about to say is I think that for Carpenter, there are certain points in his career when he seems to kind of fall off and they can be measured with the loss of a particular partner like his the quality of his movies dip real hard when Debra Hill leaves and um his yeah his scores definitely I know I know</p>
<p>Dave! (25:02.806)
Yep.</p>
<p>Dave! (25:07.77)
Leaves is, uh, is... big.</p>
<p>Bryan! (25:12.757)
but um also Alan Howarth kind of beating it is also like the point where his music kind of takes a nosedive as well. So</p>
<p>Dave! (25:23.618)
Though he did do the Village of the Damned soundtrack, he did it with Dave Davies, and it&#39;s pretty fucking good. I mean, it&#39;s the last I think it&#39;s the last good thing he did, like alone until the three of them started recording together.</p>
<p>Bryan! (25:29.269)
Hmm. But uh.</p>
<p>Bryan! (25:33.785)
Yeah. But he definitely seems to work best when he&#39;s got a partner and uh this current iteration of his music is so good like those anthologies that he put out.</p>
<p>Dave! (25:46.458)
Oh man, his last three fucking records are awesome. I will, unfortunately, so the score they do for this is really, really good. The next two are not great.</p>
<p>Bryan! (25:49.421)
They&#39;re so good.</p>
<p>Bryan! (25:56.925)
It&#39;s just kind of iterating on a concept. So yeah.</p>
<p>Dave! (25:59.282)
Yeah, but this one they really re-imagined the whole score for it and it is kick ass.</p>
<p>Bryan! (26:05.197)
it&#39;s so goddamn good. Like it really it it it kind of goes to show you how how large a role in the original movie success the music plays because a lot of the music in this one really does kind of elevate it. Also the soundtrack they did for Firestarter is fucking murder. Like I I hear that movie is <em>**</em> but I have zero interest in it but the soundtrack is just so good.</p>
<p>Dave! (26:23.402)
It&#39;s also really good, yeah.</p>
<p>Dave! (26:27.468)
I haven&#39;t seen it.</p>
<p>Bryan! (26:35.209)
Uh this movie was released in 2018. Like I said, that&#39;s the 40th anniversary of the original movie. As of this recording, it&#39;s the 45th anniversary and they&#39;re doing a whole Halloween thing in Pasadena right now where the original was shot. I&#39;ve been out there. I went to the Strode House. I went to the Myers House and I went to the Hedge and there&#39;s photos of it on our Instagram. Hey, check it out. Uh it was surreal as hell to visit those spots</p>
<p>With the exception of this of the Strode house, which looks a little different, like they&#39;re basically it&#39;s like being there in the movie.</p>
<p>Dave! (27:10.542)
Listen, you gotta give those people a lot of fucking credit for being good sports. Imagine if someone tried to cut that goddamn hedge down.</p>
<p>Bryan! (27:16.036)
The mic.</p>
<p>the house. I know the Myers house has a little note on it that says like no trespassing. So like I stepped up, got the picture real quick and left the people who own the Strode house though are extremely nice. They had they leave a little basket out with like a little laminate that has all the little facts about the about the movie and the house and like props to take pictures with and when we got there.</p>
<p>there was another guy with very young son and the when the woman who owns the place was like down on one knee like talking to the boy who was like sitting there in like a child&#39;s play shirt. It was very cute. But yeah, they got to have a lot of a lot of people coming by.</p>
<p>Dave! (28:00.782)
I mean, it&#39;s like the Amityville house. You know those people are like, Jesus Christ, I&#39;m just trying to eat fucking breakfast. Get off my lawn.</p>
<p>Bryan! (28:13.915)
I&#39;m not sure. Yeah. But Pasadena is it really does it&#39;s very weird because surrounding areas which are</p>
<p>Dave! (28:21.37)
Did they shoot this version there too?</p>
<p>Bryan! (28:23.762)
No, this was shot in South Carolina.</p>
<p>Bryan! (28:28.937)
I don&#39;t know much about South Carolina. I go to North Carolina quite often, and I hear that they&#39;re still making a lot of movies in Wilmington.</p>
<p>Dave! (28:34.958)
Dino De Laurentiis. Thank you very much.</p>
<p>Bryan! (28:38.253)
Yeah, but uh yeah, I think they shoot. I think they did the last scream movies there except for the last one where they did all the <strong><strong> in New York but yeah. So uh in the same way that Halloween H2O revises the series continuity by throwing out all the sequels after part two, this one goes back even further and assumes that no continuity exists beyond the first movie. Thank </strong></strong> god.</p>
<p>Dave! (29:01.154)
Which I&#39;m gonna say is actually a bit of a problem at one point.</p>
<p>Bryan! (29:05.427)
What do you mean?</p>
<p>Dave! (29:07.534)
And it&#39;s in the trailer when he says, wait, wasn&#39;t it her brother or something? But that&#39;s not established until the second one. So if it&#39;s.</p>
<p>Bryan! (29:11.281)
Oh, and she, no, that&#39;s the thing is that&#39;s them kind of like, you know, going, hey, wink, because she goes, because she because she says, um, no, that&#39;s just something that people made up.</p>
<p>Dave! (29:18.026)
Yeah, well, you can&#39;t have it both ways, buddy.</p>
<p>Bryan! (29:25.965)
Yeah. So, the road to production for this movie is a story of utter failure. Uh so, after Rob Zombie&#39;s remake and sequel, he bowed out. He said, I&#39;m not doing this anymore. He didn&#39;t even want to make Halloween II but I think the plan was they were going to do it anyways and he was like, <em>**</em> it. I may as well be the guy.</p>
<p>So, after that one, he was like, I&#39;m finished. The Weinstein company said, well, <em>**</em> you. We&#39;re going to do another one but the plan was to basically reboot the whole thing because this was back when reboots were all the rage. But they it floundered for years because nobody was able to settle on a script and</p>
<p>That&#39;s because many of the pitched premises had ridiculous gimmicks like one of them was a found footage movie. Another one was a 3D movie.</p>
<p>Dave! (30:20.514)
Which I would argue they&#39;ve already done.</p>
<p>Bryan! (30:23.425)
3D movie? Which one&#39;s that? Oh, yeah. Yeah. Very, very 2002. And also on the dock, it was a mockumentary. So fuck this stuff. The way that film rights work is that there&#39;s a central owner who holds the rights. In this case, it was Miramax. And then they glad they</p>
<p>Dave! (30:24.27)
Uh, no found footage. Resurrection? Where Tyra Banks gets killed with the coffee maker? I&#39;m sorry, the espresso machine. That&#39;s the best part of that movie.</p>
<p>Dave! (30:51.734)
Isn&#39;t Mustafa Akkad&#39;s grandson or son still owns it? The Akkad family still owns the rights to the movie.</p>
<p>Bryan! (30:56.621)
uh yeah but they license it through another you know through like a like an agent and so they the licensing studio only has rights as long as they&#39;re producing movies so you can&#39;t just buy an IP and then sit on it like you have to do something with it within a certain amount of time and in this case they couldn&#39;t get their shit together</p>
<p>And so the option timed out and reverted to Miramax, who then immediately turned around and sold it to Blumhouse, who just got shit done.</p>
<p>Dave! (31:29.718)
but they&#39;re all still associated with. So Universal is still associated with it. Miramax is still associated with it.</p>
<p>Bryan! (31:32.693)
Yes. Yeah. So, yeah, it&#39;s like a co- a co-product. It was like a Miramax co-production with Bloom House. Um you know and Bloom House obviously was probably the obvious by like the obvious winner here because like they&#39;ve really sort of established themselves with as like the horror producer right now. So, you know, they by this point, they don&#39;t insidious, paranormal activity, the purge, you know,</p>
<p>Personally, I can&#39;t stand that stuff, but bringing Halloween under their umbrella had like a direct effect on other productions. Like this was sort of like a case study for how like a soft reboot could be very successful. Cause this movie was.</p>
<p>Dave! (32:15.23)
Oh, yeah, it&#39;s a very AIP model where you take one movie that you know is going to make a fuckload of money so that you can turn around and make shit for like a million dollars and roll the dice on it.</p>
<p>Bryan! (32:26.185)
Yeah. Cuz what a direct result of this was we got that um that new Candyman movie which uh I thought was pretty good and then um and then also you know Scream fans have this to thank for uh the one like that Scream reboot. Um I&#39;m not a Scream guy like I understand people really like those movies. They&#39;re not for me but you know, I&#39;m glad everybody&#39;s having a good time with them.</p>
<p>Dave! (32:36.098)
Yeah, I like that.</p>
<p>Dave! (32:44.622)
NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN</p>
<p>Dave! (32:54.222)
I like the first one.</p>
<p>Bryan! (32:55.433)
Oh, I love the first one. Yeah. But but yeah, like this movie and also this movie was extremely successful. It set a bunch of fucking records for</p>
<p>Dave! (33:04.322)
For some reason it is like critically successful for reasons that I do not think have much to do with the quality of them.</p>
<p>Bryan! (33:11.942)
I think you&#39;re...</p>
<p>Dave! (33:11.994)
I don&#39;t. I think it is. I think it&#39;s very different. I think they were like, it&#39;s kind of like hereditary. We need to pick a horror movie that we can champion. This one&#39;s going to be it. I don&#39;t think anyone really gives a shit about. Plus, that&#39;s on Rotten Tomatoes, which is not an accurate read of anything.</p>
<p>Bryan! (33:25.609)
Yeah, I don&#39;t know. I think you&#39;re in the minority on this one. It&#39;s very well. It&#39;s very well made. Yeah, it is. It is a bit. It is a bit. We&#39;ll we&#39;ll talk about it.</p>
<p>Dave! (33:31.542)
I think it&#39;s a competently made movie. I think it&#39;s a fucking mess of a story.</p>
<p>Dave! (33:38.998)
And if you&#39;re gonna take something and try to reimagine what is you, what is by your definition a perfect movie, and then fuck it up so badly.</p>
<p>Bryan! (33:46.957)
Yeah, you&#39;re really rolling the dice specifically with a title like Halloween but um like I said.</p>
<p>Dave! (33:50.999)
Yeah.</p>
<p>Fun fact about this movie though is when after they made Resurrection, I think Jamie Lee Curtis said, it was asked like, would you ever make another one? And she was like, I would make another one, but the Akads would have to cut me a very, very large check. Well, something tells me someone cut her a pretty large check.</p>
<p>Bryan! (34:12.249)
Yeah, yeah. So there have been dozens and dozens of Halloween scripts bought or commissioned, and most of them can be found in a pair of books called Taking Shape by a pair of authors named Dustin McNeil and Travis Mullins. I keep intending to buy those books because they sound really, really interesting.</p>
<p>Dave! (34:29.91)
But goddamn it, if you&#39;re going to put out something like that, pay someone to make you a nice cover. Because I find the covers of those, they are so off-putting that I&#39;m like, this looks self-published, am I gonna buy this?</p>
<p>Bryan! (34:35.914)
It&#39;s such a boring cover.</p>
<p>Bryan! (34:41.969)
Yeah. So, um, there&#39;s a version of Halloween four that keeps Michael and Loomis dead. So they don&#39;t appear in the movie at all, but instead it deals with the way that Haddonfield has dealt with the killing spree in the intervening years. It, it sounds really interesting, but apparently it&#39;s all of these kind of high minded, uh, bits and pieces tacked onto a really fucking bland story.</p>
<p>Dave! (34:55.15)
I could make that movie.</p>
<p>Bryan! (35:06.581)
There&#39;s there&#39;s a version of Halloween five that sees Michael resurrected by a lightning strike. So he&#39;s at the bottom of the mine shaft And it hits him. It hits him like Jason in part six, but whatever</p>
<p>Dave! (35:14.094)
Thanks for watching!</p>
<p>Dave! (35:17.578)
Let&#39;s be honest, that&#39;s about as good as the actual plot of the fifth one.</p>
<p>Bryan! (35:22.693)
But here&#39;s the thing, whatever like malevolent force occupied him is just gone because he&#39;s just like a body walking around. So now he&#39;s just like this gentle giant. And it&#39;s like it&#39;s like Frankenstein where like everybody&#39;s like kill the monster, but he&#39;s just like, huh, I just want to be helpful.</p>
<p>Dave! (35:38.986)
I mean, aren&#39;t those basically like the movie they made after this one? Halloween Kills?</p>
<p>Bryan! (35:45.14)
Uh oh, no, no. Halloween Kills picks up. You&#39;ve seen it. It picks up exactly where the it&#39;s no.</p>
<p>Dave! (35:48.302)
I saw half of it. But it&#39;s like the same idea of like, it&#39;s about mob mentality. It&#39;s like, oh, Jesus Christ. Call that when I roll the movie.</p>
<p>Bryan! (35:54.445)
Yeah. Yeah. Michael. Uh huh. That&#39;s but that one&#39;s that one&#39;s still uh Michael like kills the <em>**</em> out of some fire. There&#39;s some firefighters with their own equipment in a scene that&#39;s pretty awesome. Um but yeah uh I gotta get those books because there&#39;s like uh there&#39;s like at least 40 sequels that were that were written but never produced. It sounds great. Uh so let&#39;s let&#39;s let&#39;s get into it.</p>
<p>So, we open on Smith&#39;s Grove Rehabilitation Facility and a pair of douchey podcast hosts who are unlike us in every single way. They are Ryan and Aaron. They meet with Dr. Sartain who is in charge of Michael Myers at this hospital and Michael is to be transferred to a different criminal psychiatric hospital. The podcasters</p>
<p>Dave! (36:43.275)
I love how they talk about, they take podcasting seriously like it&#39;s hard journalism.</p>
<p>Bryan! (36:48.237)
like, oh, dude, I, it&#39;s, it&#39;s the one. So, this is like the first point that I really just, I&#39;m like, oh, god, because like this is, this is like, yeah, like serial and you know, everything that kind of happened in the wake of like making a murderer. So, like, all of a sudden, there&#39;s this kind of like thin blue line quality to like podcasting and so, it was like the hot happening thing because this was</p>
<p>Dave! (36:57.642)
Hey, serial, what you doing? Hey, NPR, what&#39;s happening?</p>
<p>Dave! (37:11.918)
Yeah. What&#39;s that, at?</p>
<p>Bryan! (37:14.741)
like a couple of years after serial and like everybody just wouldn&#39;t shut up about that.</p>
<p>Dave! (37:16.982)
Yup. And as you know, I work in podcasting for a living and it is absolutely nothing like this in any way at all.</p>
<p>Bryan! (37:27.181)
Well, well, you&#39;re she.</p>
<p>Dave! (37:28.498)
Starting with the fact that they&#39;re walking down the hall interviewing someone. Look, if you&#39;ve ever interviewed someone for audio, you don&#39;t do it moving.</p>
<p>Bryan! (37:36.493)
Yeah. Yeah. But that&#39;s visually not terribly interesting. So, so they&#39;re going to <em>**</em> you. But yeah, they&#39;re they&#39;re there to interview Michael and Doctor Sartain. So, Sartain takes them out to the yard in a scene that looks like something out of last year at Marion Bad.</p>
<p>Dave! (37:53.534)
Yeah, this, I have, this is the first problem I have with this movie, because this is real, like this is the end of the sentinel level of like, did you think this was gonna age well? This one flu over the cuckoo&#39;s nest bullshit is like offensive in a lot of ways.</p>
<p>Bryan! (38:07.957)
Well, okay. So, yes, it&#39;s extreme and it&#39;s ridiculous but also, I worked in a psychiatric hospital and the psych no, no.</p>
<p>Dave! (38:15.494)
It is not like that. I used to spend lots of time in the psychiatric hospital, not as a patient, I worked in a separate industry. Ha ha ha.</p>
<p>Bryan! (38:20.701)
no, no. Let&#39;s let&#39;s definitely let&#39;s definitely footnote that. Um yeah, no, it&#39;s definitely it&#39;s nothing like that but the psychotic patients would get agitated and would I mean they&#39;d end up in the quiet room real fast but just give them a ball and chain and yeah but uh but yeah and sometimes they like something on them uh what is it uh</p>
<p>Dave! (38:37.45)
Well here you just chain them to the ground and give them an umbrella.</p>
<p>Dave! (38:44.086)
In this giant chessboard.</p>
<p>Bryan! (38:50.477)
Alice in Wonderland like they&#39;re gonna they&#39;re gonna they&#39;re gonna line the clients up and just like play human chess with them but uh yeah so these ones are not sedated um so yeah there&#39;s like there&#39;s a bunch of people out of this is a humongous yard they must have built this set because I have this is</p>
<p>Dave! (38:51.83)
Really, like what is this scene?</p>
<p>Dave! (38:59.158)
They&#39;re sedated, you have to push them hard.</p>
<p>Dave! (39:11.382)
Probably. Or it probably was like an abandoned prison yard or something.</p>
<p>Bryan! (39:16.585)
it could have been. Yeah, but um but like yeah so it&#39;s</p>
<p>Dave! (39:18.754)
I loved how they were like, none of them have any shoes on, because obviously you can&#39;t get them shoes, but the one guy can have a fucking umbrella.</p>
<p>Bryan! (39:24.749)
Oh, yeah. Yeah. Definitely. Definitely no potential for self harm or harm of others with a <em>**</em> umbrella. Um but but yeah but like it&#39;s it&#39;s a yard. Picture this. It&#39;s a yard. It&#39;s wide open. It&#39;s a sunny day. It&#39;s a weird like red and white checkerboard pattern and there are maniacs chained to blocks on the on the ground that among them is a very tall one. Uh he&#39;s aging.</p>
<p>Dave! (39:27.182)
Or the rusty metal? Sure, here, he can have that. Nope.</p>
<p>Bryan! (39:52.785)
and he has his back to the to the podcasters. He is biding his time. They don&#39;t call him the shape for nothing. But also a thing that I really appreciate about this movie is that they constantly keep him obscure, like his appearances never revealed, not even at the end of the last movie. He&#39;s it&#39;s completely unimportant. And so they just they&#39;ll show you his.</p>
<p>Dave! (39:55.918)
But he&#39;s respectably quiet.</p>
<p>Dave! (40:18.134)
Because I don&#39;t think you can top like they have done it one time, one time only it wasn&#39;t successful. You can&#39;t top it. You can&#39;t beat it. It&#39;s it&#39;s</p>
<p>Bryan! (40:23.561)
Yeah. It&#39;s just there&#39;s no point in doing it either. Like, who?</p>
<p>Dave! (40:30.026)
Well, because it also defeats the purpose. Now the first one, when she pushes the mask off of his face.</p>
<p>Bryan! (40:32.309)
Are you talking about in Halloween II? The the does oh that&#39;s right. Yeah, I was I was thinking</p>
<p>Dave! (40:38.91)
and you see a much younger Nick Castle.</p>
<p>Bryan! (40:41.989)
Yeah. In the in the second Rob Zombie movie, like his mask is coming apart. Yeah. But the yeah, they never throw out the movie. They&#39;re going to do this where like occasionally he becomes like separated from the mask. And they just will sort of not show you his face.</p>
<p>Dave! (40:46.128)
Yes they do.</p>
<p>Dave! (41:00.842)
Yeah, I said competently made. I think David Gordon Green is a good director.</p>
<p>Bryan! (41:04.137)
Yeah, yeah. And also it&#39;s a really good looking movie. Like aesthetically, it&#39;s just, it looks great.</p>
<p>Dave! (41:11.262)
I think there&#39;s some cheesy moments, but I think overall, yeah, I think it&#39;s well, it&#39;s very well shot.</p>
<p>Bryan! (41:16.141)
that&#39;s a good thing. Yeah. But uh out here in the field, Aaron is trying to get Michael to respond to him because the whole thing is like this move. So, what this movie assumes is in spite of his disappearance at the end of Halloween, he is picked up and he is he is incarcerated and so all of this time, he has not spoken a word in 40 years. He&#39;s never said a word to anybody. So, Aaron thinks that he&#39;s</p>
<p>Dave! (41:45.15)
Now, see, that&#39;s like more like actual podcasting. That&#39;s how it really is, everybody. But the best part is when he&#39;s the what does he say to this? The guy&#39;s like, he kind of looks back at the doctor and the doctor gives him and it&#39;s all very serious moment. Everyone&#39;s just like, the doctor&#39;s like, yes, yes. Go closer, go closer. He&#39;s like, looks back and the doctor gives him the nod and he says, I borrowed something from a friend at the attorney general&#39;s office. Bitch, that is not how anything works.</p>
<p>Bryan! (41:47.851)
Hahaha</p>
<p>Bryan! (41:59.998)
Yeah.</p>
<p>Bryan! (42:03.659)
Yeah.</p>
<p>Bryan! (42:09.777)
Yeah. That motherfucker just lost his job because uh yeah he is he is handling crime scene evidence in the in the shape of Michael&#39;s mask.</p>
<p>Dave! (42:21.898)
Which, fun fact for everybody, after a case is completed, all that shit gets destroyed.</p>
<p>Bryan! (42:28.401)
Uh well, not well, not really. I mean, they do keep it, but I mean, that shit does get lost. I mean, that&#39;s kind of what&#39;s at the center of a lot of like true crime documentaries and podcasts these days is oh shit that evidence got misplaced. So yeah, in this case, this guy, this fucking jabroni podcaster has it.</p>
<p>Dave! (42:29.554)
Unless somebody wants it.</p>
<p>Bryan! (42:49.949)
in his hand and he&#39;s sort of shaking it at Michael bellowing, say something Michael like come on man like be a</p>
<p>Dave! (42:54.686)
Yeah. And I feel like I expect this shit from Rob Zombie. It would have fit in a Rob Zombie movie. In this, it seems like this feels real, like not even exploitation, like just a cheap, lazy gimmick.</p>
<p>Bryan! (43:00.293)
Yeah.</p>
<p>Bryan! (43:12.718)
the doctor says nothing because and he this scene goes on a little too long where he&#39;s just yelling at him and then and then we and then we cut to titles and that fucking awesome theme song. Yep. I love it. God damn II. It&#39;s my that&#39;s my ringtone.</p>
<p>Dave! (43:17.214)
Yeah, there&#39;s a couple scenes in this movie that just drag on.</p>
<p>Dave! (43:26.05)
Yup.</p>
<p>Dave! (43:31.734)
while the jack-o-lantern inflates itself?</p>
<p>Bryan! (43:34.545)
Yeah, it starts out rotten and deflated and it goes in reverse. So it&#39;s lit and working again because they always do that in the movies. Like, you know, like it&#39;s the close up on the on the lit candle or something like that. Halloween Kills has a really ridiculous title scene where like pumpkins emerge from pumpkins and stuff. It&#39;s a real weird, real weird idea.</p>
<p>I&#39;m not going to go through all of this. Personally, all of this in the original script in the first one, it was like a slow, slow zoom on the mask. So, somehow they ended up doing pumpkins instead. So, yeah. Uh so, we resume with Ryan and Aaron on their way. That&#39;s the podcasters on their way to their next interview to a compound owned</p>
<p>Dave! (44:13.068)
It works.</p>
<p>Dave! (44:23.254)
My folk artist, an outsider Lori Strobel, why does she just have shit all over her fucking driveway? Like, mannequins and like weird metal fabrications.</p>
<p>Bryan! (44:32.521)
Oh, yeah, man. Well, I mean, she&#39;s been making stuff and been and practicing her shooting. We learned that Lori does not want to talk about it but they offer her a few thousand dollars and she lets him in. She&#39;s aloof. She&#39;s damaged. She&#39;s lived in fear her whole life and then Aaron wants Lori to meet with Michael and talk to them and talk to</p>
<p>Dave! (44:35.205)
What else are you gonna do?</p>
<p>Dave! (44:44.05)
Again, not a thing you do.</p>
<p>Dave! (44:55.062)
Yeah, no, in her defense, in Laurie Strode&#39;s defense, these two are fucking terrible interviewers. They&#39;re terrible at their jobs. They have clearly never taken any class on interviewing or ethics because they&#39;re just like, hey, bitch, why don&#39;t you just get in this fucking room with them? What&#39;s your problem?</p>
<p>Bryan! (45:01.601)
The.</p>
<p>Bryan! (45:08.179)
Oh no, they&#39;re ta-</p>
<p>Bryan! (45:12.329)
they&#39;re tacky as hell. They&#39;re being set up as like the opening shots for the for the for what&#39;s to come and you&#39;re not going to feel bad for when they finally get what&#39;s coming to them. Um so, they split and then we cut. We meet Laurie&#39;s daughter, Karen. Uh her husband, Ray, who&#39;s uh</p>
<p>Dave! (45:32.642)
Artie, the strongest man in the world.</p>
<p>Bryan! (45:34.701)
Yep. As soon as I saw him, that&#39;s all I thought. And then I was, but at first I was like, wait a second, where have I seen him before? And then I went, Oh, shit.</p>
<p>Dave! (45:42.41)
Toby, Toby Haas is a Toby Haas is a I told you earlier. I think he&#39;s a really underrated actor. I think he&#39;s a great actor.</p>
<p>Bryan! (45:48.757)
he&#39;s very dynamic and I look like after you mentioned him, I looked him up and I was like, oh my god, I&#39;ve seen him in a lot and I don&#39;t I don&#39;t normally think of him because I think I just think of him as already the strongest man in the world. But yep, it&#39;s this is this is to contrast what you just seen.</p>
<p>Dave! (46:08.626)
And this is a moment where the comedy does not work.</p>
<p>Bryan! (46:13.265)
Uh yes, it&#39;s it doesn&#39;t make any sense yet. So, but the thing is, is by contrast, they&#39;re shown to be or they seem to be a perfectly functional house but they don&#39;t associate with Lori and Alison wants her grandmother around and she specifically asks if she&#39;s gonna be around and her mother lies to her, you know, telling her like, oh yeah, I talked to her and she said no.</p>
<p>Dave! (46:14.762)
Because it doesn&#39;t make any sense and I don&#39;t get it.</p>
<p>Dave! (46:41.706)
Yeah, but don&#39;t worry, because Artie breaks the tension by saying, I got peanut butter on my penis. What? I don&#39;t really know what that means, but it does imply that his dick is out in the kitchen with his family.</p>
<p>Bryan! (46:47.881)
Yep. What? I don&#39;t know.</p>
<p>Bryan! (46:55.669)
I don&#39;t know. Yeah. Um I don&#39;t I don&#39;t yeah I don&#39;t get it. I if the first time I watched this, I thought, did I just hear that wrong? And then the second time I watched it and I realized nope, that was the line. Uh so on the way to school, we get a little, you know, we get a little more exposition about what happened and how none of the sequels happened in this movie. That&#39;s basically the</p>
<p>Dave! (46:58.294)
So I don&#39;t like that. I don&#39;t like that at all.</p>
<p>Dave! (47:06.699)
Oh.</p>
<p>Dave! (47:10.143)
It doesn&#39;t make any sense.</p>
<p>Dave! (47:15.312)
Cut to some more shitty people.</p>
<p>Bryan! (47:24.545)
here is if you&#39;re wondering where like Jamie Lloyd is, she&#39;s not in this movie. She was never born. That&#39;s a whole different thing. So at school, we learned that Allison has a boyfriend named Cameron and Cameron has a friend named Oscar. Both of them. Both of them suck.</p>
<p>Dave! (47:38.146)
Yep, he sucks. Now I thought if Oscar, if they made this movie in the 80s, Oscar would be played by Clint Howard.</p>
<p>Bryan! (47:49.182)
Yes, he would have.</p>
<p>Dave! (47:49.294)
Uh, and what I think is great is I think one of them says, like asks about what Allison and her boyfriend, what their Halloween costume is going to be. And she says, Bonnie and Clyde. And I thought, I can&#39;t think of a more high school Halloween couple costume than Bonnie and fucking Clyde.</p>
<p>Bryan! (48:07.161)
I know right it&#39;s a very it&#39;s a very weird</p>
<p>Dave! (48:09.299)
Oh god, and their twist on it is even more high school.</p>
<p>Bryan! (48:12.989)
It&#39;s very, yeah, it&#39;s the, but yeah, that&#39;s the thing is it&#39;s Bonnie and Clyde with a twist and they keep fucking, they keep teasing you like there&#39;s going to be some like epic payoff.</p>
<p>Dave! (48:16.939)
Okay.</p>
<p>Dave! (48:21.362)
Yeah, instead of the one you absolutely see coming. You know what they should have been? Gumby and Pokey.</p>
<p>Bryan! (48:29.085)
I agree. That would have been better. And then they could have put a twist on that. That wouldn&#39;t have mattered.</p>
<p>Dave! (48:29.614)
That would have been better.</p>
<p>Dave! (48:34.862)
would have been Eddie Murphy as Gumby.</p>
<p>Bryan! (48:37.825)
I&#39;m Gumby, damn it. So, Lori visits Allison at school while Karen is unaware.</p>
<p>Dave! (48:45.674)
Now, I think this shot is cool because it is a recreation of the shot from the original where Laurie is sitting in class and kind of daydreaming out the window. And I think it is a pretty this is a pretty good moment because it&#39;s done very well. It&#39;s subtle and it&#39;s not as fucking they don&#39;t shove it your face like they do with the other ones.</p>
<p>Bryan! (48:54.413)
Yes. Yeah. Okay. So that&#39;s</p>
<p>Bryan! (49:05.309)
Yeah. But yeah, so what she does is she gives Allison the money that the podcasters gave her. And then we get a little bit more exposition about the why of Lori&#39;s estrangement from the rest of her family and it does. She&#39;s just a maniac.</p>
<p>Dave! (49:22.718)
It&#39;s a lot of like, why can&#39;t you get over it? She&#39;s like, because he tried to kill me. It&#39;s very Sarah Connor, but without, again, without any nuance, it&#39;s like, you&#39;re just crazy now. That&#39;s all you are. That is your character. And it&#39;s like, wow, that is feminist.</p>
<p>Bryan! (49:27.174)
Yeah.</p>
<p>Bryan! (49:31.564)
Yeah.</p>
<p>Bryan! (49:39.145)
It doesn&#39;t it doesn&#39;t come across that way to me. I see I basically see them essentially lifting all of the Sarah Connor stuff. There is a little bit of that frantic hysteria.</p>
<p>Dave! (49:47.838)
Oh, the next scene is very, like, when she&#39;s, like, loading the guns and cleaning the guns, and it&#39;s like, this is, you just pulled this straight out of Terminator.</p>
<p>Bryan! (49:54.889)
Yep. So yeah, the she yeah, we cut to her shooting the shit out of the targets in her backyard at the fortress that she lives in and she cleaned.</p>
<p>Dave! (50:04.17)
Yes, and her fucking gauntlet in the backyard.</p>
<p>Bryan! (50:08.215)
The way that this house sort of proves itself at the end is pretty cool. I loved that shit.</p>
<p>Dave! (50:11.562)
Now, I&#39;m going to have questions about the house a little bit later. Did they ever say why they&#39;re moving him?</p>
<p>Bryan! (50:18.633)
the podcast. No, no. But oh, I, did I mention that? Because that is, that is kind of a critical point here is they, the podcasters wanted to talk to him because he&#39;s being moved to a different facility where they&#39;re probably not going to have access to him at all. Yeah. Uh but the, but the reasons is just seems to be like he&#39;s been there forever. The state doesn&#39;t really see any value in</p>
<p>Dave! (50:31.97)
for dot reasons.</p>
<p>Dave! (50:43.99)
So it&#39;s the first one. They&#39;re moving him to a prison, essentially.</p>
<p>Bryan! (50:47.485)
Yeah, which is what Sartain kind of alludes to is like the place that they&#39;re taking him to sucks</p>
<p>Dave! (50:53.582)
more than the chessboard that they chain him to every day. With a bunch of like hopping lunatics around him.</p>
<p>Bryan! (50:56.525)
Probably. Because later on when they actually do, when they do move him, I&#39;m like, is he at the other place already? Because this kind of sucks.</p>
<p>Dave! (51:05.002)
Yeah, real, uh, real glass housey moment there. I&#39;m talking shit about them.</p>
<p>Bryan! (51:10.445)
Yeah. But uh yeah. So, we&#39;re kind of the scene with Lori kind of doing target practice and stuff is kind of to sort of illustrate where she&#39;s at. But then we cut to a montage of Michael being transferred to the new hospital while the podcasters listen to a pretty good impression of Loomis and his testimony about Michael and while this is all happening like it&#39;s really intense like</p>
<p>contrast to what we saw earlier at Shady Grove, this place is like it&#39;s dark and there&#39;s a lot of flashlights and people getting tased and</p>
<p>Dave! (51:45.178)
Oh yeah, the montage of the voiceover is great, because he&#39;s like, I&#39;ll kill him myself if I have to.</p>
<p>Bryan! (51:50.557)
Yes, and I like I&#39;ll only rest when I press my press my ear to his chest and hear no heartbeat.</p>
<p>Dave! (51:56.886)
And now that is great because the character of Loomis is unhinged from the beginning. So to make him seem even crazier is great.</p>
<p>Bryan! (52:06.673)
Yeah. And it&#39;s completely consistent with the character too. Like it&#39;s pretty great. So, Sartain also accompanies Michael during the transfer and Lori is Doctor Sartain. It is a villainous name. Hmm. And so, Lori also watches the bus leave the hospital from her</p>
<p>Dave! (52:20.394)
His name is Dr. Sartain. What a villainous name.</p>
<p>Well done.</p>
<p>Bryan! (52:35.405)
she&#39;s like pouring nips like it just down her throat</p>
<p>Dave! (52:40.29)
So this is another thing I have a little bit of an issue. So do you have seen H2O? Now they give her a similar vibe in that one where she is. See H2O is essentially the same story. It&#39;s it is what is it like to be this kind of survivor? Even though she&#39;s functional. She&#39;s still pretty fucked up in that movie and she&#39;s an alcoholic. But she is she&#39;s not like this raging drunk that they kind of portray her to be in this where she like.</p>
<p>walks in and grabs the wine and starts chugging it like, God damn it.</p>
<p>Bryan! (53:10.733)
Oh, yeah. Yeah. Zero subtlety. No subtlety whatsoever. It&#39;s because the next scene is Allison and family with Cameron out to dinner celebrating her honor society achievement and this is where like Lori shows up and she just like swoops into the scene and just like picks up all the booze and just chugs all of it.</p>
<p>Dave! (53:34.57)
And then it&#39;s another like, Hey everybody, remember that I&#39;m crazy? And we go through that whole thing. And she refers to him as the shape. And I was like, ah, for fuck&#39;s sake.</p>
<p>Bryan! (53:37.865)
Yeah, because she has the shape. I love that though. That&#39;s so great. And also, Allison calls Lori grandmother.</p>
<p>Dave! (53:51.37)
Yes, I did. I clocked that as well. Grandmother?</p>
<p>Bryan! (53:53.409)
Yeah. Calls her grandmother like several times. And again, right before Lori shows up like Allison is like you talked to grandmother again and she&#39;s like and of course Karen is like oh yeah she said you know she&#39;s too busy she&#39;s got a thing she can&#39;t come and then in comes Lori uh like two two-fisted drinking. Uh so there&#39;s a juxtaposition here that they&#39;re going to play out throughout the rest of the movie.</p>
<p>where Michael&#39;s obsession with Laurie has kind of turned into Laurie&#39;s obsession with Michael and so</p>
<p>Dave! (54:28.85)
And maybe Lori&#39;s obsession with Lori a little bit too. Because she spends the entire movie being like, he&#39;s coming back for me. And it&#39;s like, really? He doesn&#39;t seem like he&#39;s coming back for you. He seems like he&#39;s coming back for whatever&#39;s in his fucking way.</p>
<p>Bryan! (54:40.029)
Yeah. Um they do seem to hit that point like in spite of the fact that they&#39;re that they go back before part two</p>
<p>Dave! (54:52.83)
in which he&#39;s not targeting anybody, he&#39;s just there. He&#39;s just a marauding beast.</p>
<p>Bryan! (54:52.929)
they still they&#39;re still trying to they&#39;re still trying to sort of play this idea that like all this time he&#39;s been sort of like waiting for his opportunity to kill Laurie because he&#39;s obsessed but they don&#39;t they fucking refuse to address that</p>
<p>Dave! (55:09.534)
And that is why I say they&#39;re undermining everything about the original, they&#39;re undermining that entire idea. It&#39;s like, you&#39;re not committing to anything one way or another, you&#39;re just trying to walk this line and you&#39;re not doing it very well. Like you can&#39;t have it all ways. So they wanna retcon this back to the original. Okay, fine, the original, he&#39;s just a fucking guy. Like he&#39;s a big dude, but he&#39;s just some dude. Now, if you&#39;re gonna retcon it, then you&#39;ve cut out all of the other bullshit that makes him this supernatural fucking monster, like Jason Moore, he&#39;s.</p>
<p>Bryan! (55:21.543)
Yeah.</p>
<p>Bryan! (55:28.821)
Yeah.</p>
<p>Dave! (55:38.198)
which he very clearly is in this movie because he does shit that no normal person can do. Yeah.</p>
<p>Bryan! (55:43.617)
He gets shot several times and just shrugs it off. Yeah, I, I get that. I get that.</p>
<p>Dave! (55:48.094)
And that bothers me, because it&#39;s like, you can&#39;t do both. You can&#39;t act like we&#39;re better than that, and then, oh, but we&#39;re gonna cherry pick all the shit we want from the other ones, because we need it for our fucking flimsy story.</p>
<p>Bryan! (55:59.369)
Yeah. Uh but yeah, this is the, this is the thing that they definitely they&#39;re trying not to do what part two did because they&#39;re deliberately writing it out of continuity but they still feel like they&#39;ve gotta explain him.</p>
<p>Dave! (56:20.394)
Right, they&#39;re trying to reinvent a movie without remaking it, but they&#39;re not really doing either one.</p>
<p>Bryan! (56:25.973)
I certainly would have written that a little bit differently. Like there is definitely a way to put the two of them together without making it feel like destiny. But I guess we&#39;ll never see that movie.</p>
<p>Dave! (56:36.63)
Because that is the whole point of the original story, is that it&#39;s nobody, he&#39;s nothing. You&#39;re just there in the wrong place at the wrong time.</p>
<p>Bryan! (56:40.829)
Yeah, but...</p>
<p>But here&#39;s the thing. There&#39;s something about him that keeps us coming back. Something about him that elevates the because it he is this story or the original story is functionally the same movie as Final Exam but there&#39;s something about how I know it was a yeah but like</p>
<p>Dave! (57:02.838)
without radish, without the best part of final exam.</p>
<p>Bryan! (57:08.609)
something about Michael is way more compelling than the guy in that movie. And I don&#39;t I don&#39;t know what it is. And I think it&#39;s just this intangible something that everybody is just obsessed with because like</p>
<p>Dave! (57:20.15)
because it is an insidious force. It&#39;s not, it&#39;s not a, he&#39;s just sort of there. It&#39;s, it&#39;s that idea. It&#39;s again, it&#39;s the, it&#39;s the idea of sort of structural and virulent racism. Racism doesn&#39;t have a form necessarily. We like to think that it does, but it doesn&#39;t.</p>
<p>Bryan! (57:35.549)
Yeah, but none of that was just we only found out about that because of that Halloween podcast like if I had never listened to that, I would never have understood the context of the movie.</p>
<p>Dave! (57:43.798)
But it&#39;s the idea of, it&#39;s not evil. It&#39;s the idea of like menace and meanness that has no form. He is only this thing that he represents that thing. We know that he represents something frightening. The guy in Final Exam is just a guy. He is killing because like just for, he has some reason. We don&#39;t have to really find out what it is. Michael Myers is just like a, that&#39;s why he&#39;s the shape. He&#39;s a force of something. That&#39;s also why I think he is more compelling than the other.</p>
<p>Bryan! (57:59.466)
Yeah.</p>
<p>Bryan! (58:08.245)
the force. Yeah.</p>
<p>Dave! (58:12.494)
uh franchise characters because he&#39;s not fucking goofy And at a certain point he looks goofy</p>
<p>Bryan! (58:14.889)
that&#39;s well that&#39;s the thing is that&#39;s what I&#39;m trying to say is yeah those masks in the sequels are so terrible but there is but what I&#39;m trying to say is there is something about him that like nobody can seem to put a finger on that keeps us coming back and there is this and this whatever it is this quality of him is what sort of</p>
<p>John Carpenter was finished with this movie when he when he made the first one. He said what he had to say. But everybody else who has since seen that sees something.</p>
<p>Dave! (58:44.214)
It&#39;s because you can project so much onto him because he is literally a blank thing. He wears a fucking coveralls and a mask. You can put anything you want on him. He&#39;s not Freddy Krueger, who&#39;s a fucking yuckster. He&#39;s not Jason Voorhees, who&#39;s like a rotting giant. Like he&#39;s just this thing. You can project anything you want onto him, but because it&#39;s all so blank, there&#39;s something frightening or at least unsettling about it, no matter how silly the movie.</p>
<p>Bryan! (59:00.107)
Yeah.</p>
<p>Bryan! (59:12.521)
Yeah. But there that&#39;s the point I&#39;m trying to make is there is an irresistible urge to fill in that blank. And I think that</p>
<p>Dave! (59:20.406)
Like even Rob Zombie doesn&#39;t fuck it up as bad as you think he would.</p>
<p>Bryan! (59:25.067)
he does it in a very Rob Zombie way but yeah so anyways and back to the movie flashback to Karen&#39;s childhood where basically Laurie</p>
<p>Dave! (59:35.05)
I say Karen&#39;s kind of an asshole.</p>
<p>Bryan! (59:37.837)
Karen, I understand. Karen, a little bit.</p>
<p>Dave! (59:40.014)
Karen, these skills you&#39;re learning are super useful.</p>
<p>Bryan! (59:44.566)
she can fight, she can shoot, she can do all kinds of crazy, she could like improvised explosive devices like she is a very talented child. The thing is when she turns twelve, this child protective services came in and relieved Lori of her daughter.</p>
<p>Dave! (59:46.378)
Yeah, I mean, you maybe you don&#39;t need them a lot, but when you need them...</p>
<p>Dave! (01:00:00.65)
Which I don&#39;t think you can actually do. Takes a lot to remove a child.</p>
<p>Bryan! (01:00:02.765)
probably not but so we cut to father and son on the way out to go hunting and I really</p>
<p>Dave! (01:00:11.226)
I fucking hate this scene for so many reasons. And it is the it is the embodiment of all that is wrong with this movie. And I&#39;m going to tell you why, why this is the whole the whole vibe of this is like this is not your father&#39;s Halloween movie, like we&#39;re for the now the here and now. It is and I hate to say it, I hate to use this word. This is the woke Halloween.</p>
<p>Bryan! (01:00:15.535)
I think it&#39;s funny!</p>
<p>Bryan! (01:00:20.55)
Oh man, so it&#39;s</p>
<p>Bryan! (01:00:33.322)
Ah, yeah, yeah. I see what you&#39;re saying.</p>
<p>Dave! (01:00:40.702)
And every time they try to do this shit, they just jam it in there. It makes no fucking sense. And then you brutally kill them both, which undermines the whole thing that you&#39;re trying to do with this fucking scene.</p>
<p>Bryan! (01:00:53.501)
I think the conversation&#39;s really fucking funny because it doesn&#39;t go the way you think it&#39;s gonna go because the kids like it also it is a little weird that they&#39;re going hunting this early but uh or this late but the kids missing dance class but his dad&#39;s just like oh so you&#39;re really into that like you don&#39;t want to go hunting with your old man and he&#39;s like well you know i&#39;m just like dancing&#39;s really kind of a thing right now but then they come across they come upon the hospital bus which is stopped in the road and there&#39;s crazy maniacs wandering around all over the place</p>
<p>Dave! (01:01:20.784)
Yep. It&#39;s Bedlam. Literally.</p>
<p>Bryan! (01:01:23.057)
And then the implication here is that Michael has escaped. The father gets out first while the kid calls the cops and then the kid gets out with a rifle looking for his dad and he finds that the guards have all been brutalized. It goes up on the bus and he sees like he&#39;s got his gun like ready to go and Sartain comes out and he goes don&#39;t shoot. The kid shoots him. I did too and uh and then he</p>
<p>Dave! (01:01:43.053)
I laughed out loud.</p>
<p>Dave! (01:01:48.834)
There were two moments in this movie, one where I laughed out loud and that was this, and there was one where I audibly booed at the screen.</p>
<p>Bryan! (01:01:54.637)
And so the kid goes back to his car and this is where Michael is hiding and he kills him which I was surprised by because it definitely like says oh <strong><strong> like everybody&#39;s up for grabs because there&#39;s a scene a little later on where I was like oh </strong></strong> is he gonna kill that baby?</p>
<p>Dave! (01:02:12.242)
Oh, but that also is super, it&#39;s a little intentional. I don&#39;t like the dancing. There&#39;s a it&#39;s kind of a fraught scene, the conversation they&#39;re having. And it gets there. It is they make the same mistakes in the next one as well, where there are certain kill scenes where it&#39;s like you didn&#39;t need to do this. You did this on purpose and you didn&#39;t need to. It&#39;s real shitty.</p>
<p>Bryan! (01:02:34.305)
But Deputy Hawkins is a new character. He responds to the call to check out the bus crash. He finds everybody dead, seriously fucked up. The kid&#39;s father from the previous scene is seriously all jacked up. Like his head is all bent backwards and like a weird angle and stuff.</p>
<p>Dave! (01:02:50.57)
I was wondering if that was the father. It&#39;s lit kind of poorly and you can&#39;t really tell.</p>
<p>Bryan! (01:02:53.713)
it is. It&#39;s hard to tell but I think that&#39;s supposed to be that&#39;s supposed to be the kid&#39;s father and then we find out that Sartain is still alive and he&#39;s concerned that Michael escaped. So the next day is Halloween and the podcasters visit Judith Myers&#39;s grave site.</p>
<p>and Michael watches them from a distance and of course his face is obscured and there&#39;s a long wind up to when the mayhem actually begins and again it&#39;s kind of like the first one where there&#39;s a bit of a period where the shape is like wandering around like in hospital garb and so the same thing&#39;s kind of happening here.</p>
<p>Dave! (01:03:32.726)
Now here&#39;s my question about that though, because there is something, obviously I don&#39;t care for this movie, but there&#39;s something missing from it. That there&#39;s something like that they&#39;re trying to do that they&#39;re not hitting or something. And I wonder is it there just, there&#39;s too many people in this movie. So in the original, there&#39;s all this, you&#39;re saying there&#39;s this windup. Yeah, it&#39;s based on these three people and you get to like them, you get to love them. I don&#39;t know who any of the people are in this movie. And I think there&#39;s just too many of them.</p>
<p>Bryan! (01:03:48.225)
That&#39;s okay.</p>
<p>Bryan! (01:03:52.393)
Well, sure, it&#39;s.</p>
<p>Bryan! (01:03:57.585)
I think, well, okay, I&#39;m going to stop you and think that it&#39;s really unfair to compare this movie to that movie because</p>
<p>Dave! (01:04:04.718)
I don&#39;t think it is unfair since they are constantly referencing it. If they were like, no, this is a new thing, fine, but this is not a new thing. They keep calling back to it.</p>
<p>Bryan! (01:04:08.338)
It&#39;s a, it&#39;s a.</p>
<p>Bryan! (01:04:12.437)
But it is a new thing. They keep calling back to it because they gotta like, you know, there&#39;s so much pointing back to be like.</p>
<p>Dave! (01:04:18.262)
But I&#39;m just wondering in general, is there just too many people in this movie? Because I can&#39;t, I don&#39;t know who is who, I don&#39;t know anybody&#39;s names, and you don&#39;t spend enough time with any of them to get attached to anything.</p>
<p>Bryan! (01:04:22.101)
There are way too many people in this movie.</p>
<p>Bryan! (01:04:30.837)
the podcasters are my least favorite characters in the movie and there&#39;s no but also in functionally because you spend a not a shitload of time but like way more time than you expect. You expect them in the way that they&#39;re featured in this like first third of the movie to be players like you&#39;re going to Yeah but they&#39;re not and then once they&#39;re gone, you never fucking think of them again.</p>
<p>Dave! (01:04:33.738)
And I think you&#39;re supposed to not really like-</p>
<p>Dave! (01:04:52.106)
Well, then they&#39;re gonna be in it for at least a while.</p>
<p>Dave! (01:04:59.466)
And I feel like this, but that&#39;s how I feel like a lot of the characters in this are, is they&#39;re just sort of there and then they&#39;re gone. And I don&#39;t really know who they are or why they&#39;re there, but I would have preferred less people so you can actually get a sense of the other characters who you are going to be with and who you do care if they die.</p>
<p>Bryan! (01:05:15.061)
Yeah, because that&#39;s the thing that makes the original so fucking effective is it&#39;s tight and you know, it gives you exactly what you need.</p>
<p>Dave! (01:05:22.218)
Or any horror movie in general, you have to spend enough time with them to know at least know their names. Like, I don&#39;t know any of these people.</p>
<p>Bryan! (01:05:27.785)
sure, sure. I mean, that&#39;s I mean, there&#39;s a lot of movies that do that and that&#39;s you know, I it&#39;s just it&#39;s a quality of a of a movie that&#39;s trying to do too much. I think because there&#39;s also there&#39;s a couple of other characters who are like in this and they&#39;re featured in a way that makes you think you&#39;re going to be getting more of them like the sheriff. He he appears a couple of times and you kind of get the feeling that you know, get used to seeing this guy and then he kind of vanishes.</p>
<p>Dave! (01:05:47.799)
Yeah.</p>
<p>Dave! (01:05:54.622)
And I feel like that&#39;s kind of, they&#39;re trying to do both things. They&#39;re like, oh, you like this person? Nope, now they&#39;re gone. It&#39;s like, oh, okay, but I didn&#39;t get to like them. You didn&#39;t give me enough time with any of them because you&#39;re constantly dropping all these new people in for no particular reason other than to be sort of chum for the waters.</p>
<p>Bryan! (01:06:00.182)
Yeah.</p>
<p>Yeah.</p>
<p>Bryan! (01:06:11.637)
Yeah. But now we go back. We cut to the hospital where Sartain is being like tended to and Hawkins informs the sheriff that Michael Myers was on that bus and is missing on Halloween. So,</p>
<p>Dave! (01:06:27.658)
And then he tells the story. Did they reshoot the stabbing scene?</p>
<p>Bryan! (01:06:34.758)
Uh, yeah, I think, I think they did. That&#39;s not original footage.</p>
<p>Dave! (01:06:35.051)
of the sister?</p>
<p>Dave! (01:06:39.182)
Because I don&#39;t think, I don&#39;t remember in the original one, you don&#39;t actually see any nudity. Or in that, not in that scene.</p>
<p>Bryan! (01:06:43.465)
right, right. Yeah. Um no, I think that was shot brand new for this one. But uh so now we jump back to Ryan and Aaron, the podcasters who started to look their names up. Um they stopped for gas at a nasty gas station.</p>
<p>Dave! (01:07:01.874)
and where they make everyone look like white trash.</p>
<p>Bryan! (01:07:04.501)
this is like this is like hey, do you like that Rob Zombie movie? Yep, you&#39;re gonna meet big</p>
<p>Dave! (01:07:08.082)
Yeah. But I&#39;ll tell you what I really did like is when he&#39;s standing, he&#39;s pumping the gas and he looks over and there&#39;s the woman looking at him from the van and he sort of like flashes her a peace sign or you know, whatever the kids do these days and the look that she gives him, I really loved because I was like, that is the exact look I would give him if someone did that.</p>
<p>Bryan! (01:07:19.113)
Yeah.</p>
<p>Bryan! (01:07:24.894)
I&#39;m out.</p>
<p>Yeah. But uh yeah, so she heads off to the bathroom while Michael beats the mechanic to death. And then while she&#39;s in there, he walks in on her because now he&#39;s in the coveralls. So he&#39;s almost got his costume complete. And then he menaces her by reaching over the stall door and dropping the mechanics teeth in front of her.</p>
<p>Dave! (01:07:52.93)
Listen, I got a question for you. What would you do if you&#39;re taking a shit in a public bathroom and someone sticks their hand over the stall door and drops a handful of teeth on the floor?</p>
<p>Bryan! (01:08:02.665)
I would resign to my fate. That&#39;s what I would do. Yeah, I gotta tell you, this is what this is one of the scenes that I definitely had a problem with because I don&#39;t like sadistic</p>
<p>Dave! (01:08:04.426)
Yeah, I mean, what else can you do?</p>
<p>Dave! (01:08:15.294)
Now, and that is exactly what this movie is. The violence in this movie is mean and unnecessary.</p>
<p>Bryan! (01:08:20.997)
it&#39;s well, I mean he I don&#39;t think this is one of the only scenes where I feel like it goes a little it goes it&#39;s uncharacteristic because the killer he just kills. It&#39;s not there&#39;s no pleasure in it. He just does it to do it. He&#39;s a destroyer. Right and so like this scene though there is something.</p>
<p>Dave! (01:08:34.462)
Right. This is this is Rob Zombie&#39;s Halloween. Where it is brutality.</p>
<p>Bryan! (01:08:43.421)
Like it would be, it&#39;s a great scene in a different movie. Like this would have been great in terrifier, but like it&#39;s, it just, it&#39;s, it&#39;s not the kind of killer that the shape is, you know?</p>
<p>Dave! (01:08:52.726)
Though I do like the moment where he drops the tea. I don&#39;t like what follows, but I do like that moment. I think it&#39;s pretty.</p>
<p>Bryan! (01:08:55.29)
I do like it too!</p>
<p>Bryan! (01:08:59.309)
Yeah. And then anyways, then he attacks her. He bursts in and tries to drag her out. Um and you know, there&#39;s a he wants the mask. He could have just taken the <strong><strong> mask. But well, but also he&#39;s gotta kill because he&#39;s gotta kill because he also he&#39;s already killed the attendant. He killed the mechanic. He&#39;s just gonna </strong></strong> kill everybody. Maybe he and they suck and you really like I don&#39;t I don&#39;t</p>
<p>Dave! (01:09:05.086)
And this is all because he wants the mask.</p>
<p>Yeah.</p>
<p>Dave! (01:09:19.551)
And these two suck.</p>
<p>Bryan! (01:09:25.481)
I don&#39;t take the pleasure of their deaths, but I&#39;m relieved that we don&#39;t have to deal with them anymore. But yeah, he attacks her and then Aaron comes in with a with a tire iron and he attacks. He hits Michael with it real hard, but of course, it&#39;s the shape and so it just bounces off of him. He gets it and he smashes his head in like through like into the wall a bunch of times and then he strangles Ryan, the girl.</p>
<p>and then you know that&#39;s that he goes out to their car and he recovers the mask so it&#39;s a cool scene the way he does it too like the it&#39;s like sort of sort of shot through the car window you know aesthetically very cool</p>
<p>Dave! (01:10:05.518)
Mm-hmm.</p>
<p>Guys, a lot of shots.</p>
<p>Bryan! (01:10:10.217)
Yeah. Uh but back at Lori&#39;s, she&#39;s making breakfast while the news is on. She finds out about the bus crash and immediately readies herself for the inevitable. And uh we find out here that she has a panic room hidden under the kitchen.</p>
<p>Dave! (01:10:24.814)
Which I... I don&#39;t know if I&#39;d call it a panic room exactly.</p>
<p>Bryan! (01:10:28.181)
Well, no, no. We&#39;re supposed to think it&#39;s a panic room, but we&#39;re going to find out it&#39;s a trap. Yeah. And so back at Karen&#39;s, Laurie surprises Karen and Ray with a gun. She&#39;s like, bang, I got you. You&#39;re dead. And so she&#39;s her whole thing is she&#39;s going to get him to safety and she&#39;s really, really wound up in manic. But now this is where we learn that.</p>
<p>Dave! (01:10:31.631)
Oh god...</p>
<p>Dave! (01:10:41.891)
I kind of love this moment</p>
<p>Bryan! (01:10:56.893)
she&#39;s not just going to hunker down her whole intention is to find him and kill him</p>
<p>Dave! (01:11:02.774)
Which is always sort of unclear, kind of until the end where they kind of crystallize that. But again, it&#39;s that idea of like, oh, this is the tragic victim. And it&#39;s like, well, now you&#39;re making her look like some weird vigilante, which is it?</p>
<p>Bryan! (01:11:08.364)
Yeah.</p>
<p>Bryan! (01:11:16.285)
Yeah. I would have like in my in my vision for this movie like there this would have just been Michael going back to Haddonfield to sort of do what he does and her just reacting to that. So I would have definitely gone. I have not seen that. The the what? The the new one right? Yeah, I&#39;ve not seen it. I&#39;ll have to watch it.</p>
<p>Dave! (01:11:28.694)
So basically the Texas Chainsaw Reboot.</p>
<p>I don&#39;t know. I hated it the first time. I warmed up to it the second time. But Netflix won, yeah.</p>
<p>It&#39;s basically that premise where it&#39;s like Sally Harnesty is like sort of like, ugh, if I have to, okay.</p>
<p>Bryan! (01:11:49.093)
the So, it&#39;s not Dennis Hopper as her uncle. So, at the gas station, which is now a crime scene, Hawkins informs us that he was there on the night in Haddonfield in 1978. That&#39;s going to sort of bear out a little bit. Um ish. Uh it&#39;s more I think they definitely flub it</p>
<p>Dave! (01:11:53.195)
No, but that would have been awesome.</p>
<p>Dave! (01:12:05.615)
ish.</p>
<p>Dave! (01:12:15.862)
Yeah, there&#39;s a bunch of stuff in this, but that feels it feels like to me, it&#39;s like you had other ideas for these things that probably got cut and you just didn&#39;t really fix up the earlier parts.</p>
<p>Bryan! (01:12:17.182)
Your Halloween ends.</p>
<p>Bryan! (01:12:23.721)
Yeah. There&#39;s there&#39;s also a lot of deleted scenes on the disk and</p>
<p>Dave! (01:12:29.586)
Yeah, it has that feel to it. It&#39;s like this feels like it was supposed to go somewhere and it kind of inevitably doesn&#39;t.</p>
<p>Bryan! (01:12:35.845)
Yeah, and some of the deleted scenes, like I can tell why they left them out because they&#39;re fucking stupid, but some of them also are like critical plots, or rather not critical, but they&#39;re like plot details that would have sort of helped some of these other moments out a little bit. And, um...</p>
<p>Dave! (01:12:51.114)
Well, here&#39;s what you do. You make a television version and you just cut those new scenes right back in. And that&#39;s what they did with the original one.</p>
<p>Bryan! (01:12:59.338)
Yeah. But so now the shape has found his way to town on Halloween night and thus begins this killing spree.</p>
<p>Dave! (01:13:08.542)
Now let&#39;s talk about how you&#39;re going to retcon back to the first one and then lift a scene straight from the second one.</p>
<p>Bryan! (01:13:17.314)
the hammer? Yeah.</p>
<p>Dave! (01:13:18.366)
Yes, to hold that whole scene. Except they take it much further, unnecessarily far.</p>
<p>Bryan! (01:13:24.293)
So the shape, he, oh yeah, also we get a cameo from the three masks in Halloween three.</p>
<p>Dave! (01:13:30.542)
Mm-hmm. Again, there&#39;s like, I just get it. See you in the moon.</p>
<p>Bryan! (01:13:33.629)
Yeah, yeah. I love it. I love it. The first victim gets smashed in with a hammer. And Michael kind of helps himself to lose weapon of choice in her kitchen, a butcher&#39;s knife. The way that this scene is shot is I don&#39;t think it&#39;s a one shot, but it feels that way. At least in my memory, it is kind of like the I don&#39;t think it is, but it very much feels that way. The next one, however, is very clever.</p>
<p>Dave! (01:13:53.022)
I don&#39;t think it is.</p>
<p>Bryan! (01:14:01.897)
in the way that they actually they did it and that&#39;s definitely a one shot. So next door he finds another woman in her house alone and he watches her</p>
<p>Dave! (01:14:09.13)
Which this was another one where I&#39;m like, why is he just randomly killing these people? If his whole thing was like, he&#39;s coming back for her, but that&#39;s the thing, is that what he does or is he coming back for her? Make up your fucking mind.</p>
<p>Bryan! (01:14:14.101)
Because that&#39;s what he does!</p>
<p>Bryan! (01:14:23.181)
Yeah, yeah, he&#39;s just a destroyer. Sartain describes him like a shark a little later on, which is, that&#39;s a great name. It sounds like a G.I. Joe character. So,</p>
<p>Dave! (01:14:27.414)
So every time you say Sartain, I think, God, that&#39;s a good name.</p>
<p>Dave! (01:14:35.76)
Um, but Loomis describes him as a shark in the original. But we can&#39;t compare it to the original, even though they&#39;re constantly doing that.</p>
<p>Bryan! (01:14:38.363)
Yes. Yeah.</p>
<p>Bryan! (01:14:43.355)
the camera stays static on the window and that&#39;s when he like does the thing where kind of crouches down and you see his reflection in the window which is really spooky and then</p>
<p>Dave! (01:14:51.99)
What I like about this, that feels very, that shot feels very 80s to me. Like it feels like it&#39;s borderline exploitation where it&#39;s like, this is as much as you&#39;re gonna get away with per the MPAA. And so it&#39;s like going as far as they can. Like they could have taken it much further, but I really liked that shot. I don&#39;t think it&#39;s necessary.</p>
<p>Bryan! (01:14:57.147)
Yeah.</p>
<p>Bryan! (01:15:12.097)
So, yes. So, it&#39;s the camera stays put and he kind of gets up and walks around the side and the thing is from where we are, we can see the side windows and you like to see him just kind of like nimbly make his way across, you know, the side and then he comes in behind the woman and he and he stabs her.</p>
<p>Yeah, moving like a cat and I think at the high school Halloween dance now, Allison gets a call from her friend Vicki who is big, who is babysitting Julian, a kid who is hilarious and yeah, and they&#39;re watching repo man completely unaware that being a babysitter on Halloween and Haddonfield is dangerous as hell.</p>
<p>Dave! (01:15:38.788)
Ah, Jesus.</p>
<p>Dave! (01:15:43.018)
Now I love this kid, he&#39;s the fucking star of the movie.</p>
<p>Dave! (01:15:52.466)
I went by all of the point where he asked her, How much do you get paid?</p>
<p>Bryan! (01:15:57.506)
something about I&#39;m just over here clipping my nasty toenails. He&#39;s the best. He&#39;s the best. I would watch an entire movie of Julian. So, back at school, Allison catches Cameron kissing another girl and then</p>
<p>Dave! (01:16:00.574)
Yep. He&#39;s great.</p>
<p>Dave! (01:16:12.226)
Ah, fucking Cameron. You know, here&#39;s the thing, one of the most disappointing things about this movie. Cameron is obviously a piece of shit, and you totally expect him to get murdered, and he doesn&#39;t.</p>
<p>Bryan! (01:16:16.481)
K.</p>
<p>Bryan! (01:16:21.125)
I know. So, this reminds me of I when I was watching this, this reminded me of Prom Night because there&#39;s that scene where the girl and I can&#39;t remember the character&#39;s name. It&#39;s she&#39;s they&#39;re in like the locker room and he&#39;s like trying to have sex with her and she&#39;s like almost there and yeah and he walks.</p>
<p>Dave! (01:16:39.698)
Oh, he&#39;s the lame one from the beginning.</p>
<p>Bryan! (01:16:44.689)
and just lives. We never see him again. He absolutely should have gotten fucking killed but uh Slick, the caring lover with a sweet van, he gets killed. What is up with that? Yeah but uh so yeah. So, what ends up happening is they argue and uh the phone starts to ring and it&#39;s her family that wants her to come home because you know, Michael Myers is out trying to kill everybody and so he grabs the phone and he throws the phone and you know, a punch ball of what looks like pudding.</p>
<p>Dave! (01:16:54.394)
Now, he is the best.</p>
<p>Dave! (01:17:14.154)
Yeah, I think is this supposed to be like Jello or something?</p>
<p>Bryan! (01:17:14.401)
and I have no idea. It looks really gross but yeah, so now she misses a very important call about her family and the status of Michael Myers. Laurie, meanwhile, is cruising around Haddonfield looking for the shape who is now at Julian&#39;s house. Now, Vicki&#39;s boyfriend shows up and he is by</p>
<p>Dave! (01:17:34.246)
Okay, now, Vicky&#39;s boyfriend. I was surprised because I thought he comes across as the queer friend.</p>
<p>Bryan! (01:17:42.839)
I was gonna say, he&#39;s way nicer than Cameron. Also, is he really a farmer? Cause he&#39;s like, hey, here&#39;s a pumpkin right from my patch.</p>
<p>Dave! (01:17:51.134)
And it&#39;s expertly carved, no less. I just assume farmers know how to carve pumpkins.</p>
<p>Bryan! (01:17:53.205)
Yeah. And so the two probably. Yeah. Uh you know, I&#39;m gonna tell you what. For somebody who&#39;s such a Halloween enthusiast, Halloween the, you know, the holiday, uh I fucking hate carving pumpkins.</p>
<p>Dave! (01:18:03.708)
Holiday.</p>
<p>Well, it&#39;s very messy and it is a lot harder than you&#39;d expect.</p>
<p>Bryan! (01:18:11.017)
Yes, that is exactly it. And my fucking children always want something elaborate that ends up giving me carpal tunnel syndrome. So</p>
<p>Dave! (01:18:19.558)
And you always buy those kits and they come with little tiny saws and it&#39;s like, do you know what a pumpkin is?</p>
<p>Bryan! (01:18:25.998)
Yeah, no, it is a hard shell that is just these tools are not going to crack it.</p>
<p>Dave! (01:18:30.346)
Yeah, meanwhile, they&#39;re giving you like the tiniest wood carving tools.</p>
<p>Bryan! (01:18:33.621)
Yeah, I know. So, the two of them are making out and then Julian panicked, informs them that there&#39;s someone in the house and Vicki looks and finds nothing and then she convinces Julian to go back to bed, rookie move, and then she goes to close his closet, but it won&#39;t.</p>
<p>Dave! (01:18:50.902)
Yeah, she does a shitty, she does like a shitty joke where she&#39;s like pretending there is someone there and it&#39;s like, bitch, he is obviously upset. Why are you doing that?</p>
<p>Bryan! (01:19:00.402)
they got a thing though. Like they&#39;ve really got a they got a rapport. Yeah, like they&#39;re tight. They&#39;re tight bros. And for some reason now, like they have separated the boyfriend like he&#39;s out in the garage like playing with them.</p>
<p>Dave! (01:19:02.608)
I guess.</p>
<p>Dave! (01:19:11.766)
Cause for some reason he&#39;s like, I&#39;m just going to go get on this person&#39;s motorcycle and then turn it on.</p>
<p>Bryan! (01:19:16.777)
but he&#39;s also high on the marijuana. So maybe he just his judgment was lapsed. But meanwhile, yeah, we go back and she&#39;s trying to close the closet door and closet door won&#39;t close. So she opens it to see what the problem is. And it turns out that the shape is the problem because he&#39;s in there. Stabs the shit out of her while Julian flees.</p>
<p>Dave! (01:19:31.594)
And I...</p>
<p>Dave! (01:19:36.622)
That was kind of boring. This scene is just like, could you have done something better?</p>
<p>Bryan! (01:19:42.793)
It&#39;s tense. It&#39;s I it&#39;s you know they&#39;re winding they&#39;re winding you up. They&#39;re giving you a little a little second act action. Her boyfriend.</p>
<p>Dave! (01:19:50.967)
I mean, if you want to see real good closet kills, what a Christmas.</p>
<p>Bryan! (01:19:56.174)
Yeah. Her boyfriend grabs an even bigger butcher knife goes</p>
<p>Dave! (01:19:59.89)
Oh right, because he comes running up the hall because my note says, twas the slippery floor that did Vicky in.</p>
<p>Bryan! (01:20:05.937)
right? Like, you don&#39;t even see anything. It&#39;s just that she&#39;s wearing socks. Man, that floor just goes right out from under her. Yep. It&#39;s like whenever you go to the hospital, they give you those no slip socks, obviously for a reason in case Michael Myers is chasing you. Yep. But uh yeah, he goes, he goes up to help her and that is the last we see of him for a little while. So, after a time, Hawkins drives up on the crime scene and Lori arrives not long after in her own car just freaking out on everybody in the street.</p>
<p>Dave! (01:20:09.366)
Yep. And we&#39;ve all had that moment.</p>
<p>Dave! (01:20:18.881)
Mm-hmm.</p>
<p>Dave! (01:20:35.734)
Yeah, she&#39;s a menace.</p>
<p>Bryan! (01:20:41.454)
the</p>
<p>Dave! (01:20:57.59)
They should have got Dick Warlock to do it. That guy&#39;s too busy being awesome. He&#39;s at Halloween 45.</p>
<p>Bryan! (01:21:06.246)
Unsurprisingly.</p>
<p>Dave! (01:21:07.838)
Oh yeah, there&#39;s like real fucking fringe characters there. There&#39;s like literally just get everybody.</p>
<p>Bryan! (01:21:11.725)
Yeah. They&#39;re all they&#39;re all charging 150 bucks for an autograph. So what she does is she takes a shot at him. She shoots him through the window, but it turns out that she shot his reflection at a mirror. I love this scene. It&#39;s fucking great because like he doesn&#39;t again that shit like a cat like he just.</p>
<p>Dave! (01:21:18.776)
Yup.</p>
<p>Dave! (01:21:29.019)
I hated this scene so much. Oh God.</p>
<p>Bryan! (01:21:37.517)
exits the scene like he and he&#39;s in no hurry even though there&#39;s an armed police officer in the next room and so he gets out as Hawkins sort of chases him down the down the steps. Michael gets outside and Laurie catches him and cracks another shot off and legitimately shoots him in the shoulder and he just keeps going.</p>
<p>Dave! (01:21:56.141)
Mm-hmm.</p>
<p>Bryan! (01:22:01.681)
So now, Sartain shows up at the crime scene with the rest of the police. He turns out that the sheriff got him out of the hair, out of the hospital to help them because you know nobody knows him better than uh than Dr. Sartain. You know, Laurie dubs him the new Loomis. So this is where Sartain describes him as like a shark. He only knows how to keep moving and keep killing. And so meanwhile, Allison has left the</p>
<p>Dave! (01:22:06.485)
Yeah</p>
<p>Dave! (01:22:28.866)
Which I feel is an unfair characterization because he has literally been sitting quiet for the last 40 years. So he does know how to stop and do anything or nothing. He is not at all like a shark, they can&#39;t do that.</p>
<p>Bryan! (01:22:38.289)
Yeah. Nope. So, yeah but we&#39;re going to find out like Doctor Sartain. He&#39;s not a very good doctor.</p>
<p>Dave! (01:22:47.001)
No.</p>
<p>Bryan! (01:22:49.874)
the party with Cameron&#39;s friend Oscar who walks her home. Yeah, I know. And then Hawkins is now out riding around with Sartain. We get a look into Sartain&#39;s fascination with Michael and he wants to know what Michael gets out of murder. You know, he&#39;s</p>
<p>Dave! (01:23:04.926)
Now this also feels a little cult of thorn, is that what it&#39;s called? That bullshit from the later ones.</p>
<p>Bryan! (01:23:16.031)
I don&#39;t know. Yes. Yeah. Um I more the it&#39;s yeah. Okay, you&#39;re saying with that. Uh but thing is we&#39;re starting to bit better and like something&#39;s</p>
<p>Dave! (01:23:17.882)
It has that vibe though, where it&#39;s like I&#39;m so obsessed with you.</p>
<p>Bryan! (01:23:36.449)
So now, Lori and the police grab Karen and Ray, while Oscar and Allison cut through some dude&#39;s yard that is surrounded by a horror movie wrought iron fence, the kind that people inevitably end up getting hung up on.</p>
<p>Dave! (01:23:47.518)
Now, if you haven&#39;t seen the movie, it&#39;s important to know that Oscar is the friend. He&#39;s a little bit chubby, he&#39;s exceedingly nerdy. And so I think you can see where this is going to go.</p>
<p>Bryan! (01:24:01.764)
the fucking guy from Jonah Hill. I get the feeling that they wrote this character with Jonah Hill in mind.</p>
<p>Dave! (01:24:06.973)
Mmm.</p>
<p>Dave! (01:24:10.766)
Okay, I can see that.</p>
<p>Bryan! (01:24:12.945)
Yeah. So, this whole time, like, he&#39;s been being, he&#39;s being nice to her. He&#39;s complimenting her. And then, like, when we finally get back to this scene, he kisses her while they&#39;re in the backyard of this, like, some neighbor&#39;s house. And she&#39;s like, oh, god, what the fuck? Understandably.</p>
<p>Dave! (01:24:30.562)
This is another moment where I was like, oh, for fuck&#39;s sake. Like, why couldn&#39;t you just let the friend just be the friend? Like, why can&#39;t you imagine a world in which every guy is not trying to violate her in some way? Now, I feel like maybe at this point, maybe that&#39;s just naive on my part, because it seems like every time I turn around, someone you used to respect is a rapist.</p>
<p>Bryan! (01:24:38.112)
I-</p>
<p>Bryan! (01:24:43.322)
I know that&#39;s I had the same</p>
<p>Bryan! (01:24:47.987)
Hahaha</p>
<p>Bryan! (01:24:52.093)
Yeah, yeah.</p>
<p>Dave! (01:24:54.422)
But I just like the from the moment that scene starts, I was like, oh, I see. The nerd is going to misread the signals and because of that, he has to die. Ugh.</p>
<p>Bryan! (01:24:58.453)
I know, I, it&#39;s.</p>
<p>Bryan! (01:25:04.781)
I don&#39;t know if he has to die because of it. He just was in the wrong place. Cuz yeah, cuz what ends up happening is she is understandably like what the <em>**</em> Charges off, climbs over the fence on her own, and he sits there and he catches a glimpse of a shadow, a shape you might say, in the dark.</p>
<p>Dave! (01:25:06.762)
Well, they seem to think so.</p>
<p>Dave! (01:25:22.518)
But he does the same. He does something similar to what the boyfriend does at the party. And this is that idea of that exhausting conversation about gaslighting we&#39;ve all had to have for the last God knows how many years, where it&#39;s like he&#39;s trying to explain it away like I&#39;m drunk or I&#39;m didn&#39;t do this, that and the other thing. And it&#39;s like, again, it&#39;s like, OK, not every interaction that a young woman has involves this. But it does sort of have that vibe of like, well, her boyfriend did it. Now this guy&#39;s doing it too.</p>
<p>Bryan! (01:25:36.836)
Oh yeah, yeah.</p>
<p>Bryan! (01:25:49.901)
Yeah. But, uh, yeah, he thinks that the shape in the darkness is this neighbor. He couldn&#39;t be more wrong. Because the shape attacks him, and he stabs him while he&#39;s trying to climb over the fence, and then he hangs him up on the spikes of the fence by his jaw. Great effect. Now, Allison goes back to see what the commotion was all about.</p>
<p>and comes face to face with the shape. And it has that fucking awesome music sting that happens because she at first just sees Oscar&#39;s body on the fence. And then he kind of like emerges from the gloom. And it&#39;s like that wha kind of thing that Carpenter&#39;s always doing.</p>
<p>Dave! (01:26:18.291)
Mm-hmm.</p>
<p>Dave! (01:26:29.014)
Yeah, the music is a character unto itself and it is fucking brilliant.</p>
<p>Bryan! (01:26:33.237)
Yeah. And so she runs until she&#39;s rescued by Hawkins and Sartain, who is adamant that Michael must not be harmed. And so now we come back to Laurie&#39;s basement. She arms Karen and Ray with rifles. And then back out on the streets, Hawkins spots the shape walking around and he rams him with his car kind of like in part two, where the van runs the guy over, but it&#39;s just some dude in the mask.</p>
<p>Dave! (01:27:02.742)
Yeah, it&#39;s Ben Tramer.</p>
<p>Bryan! (01:27:05.901)
But it&#39;s this is now this is this is this is actually Michael. And so now with Michael laid out Hawkins gets out his intention is he&#39;s going to shoot him in the face. And so just you know to make sure to make sure he&#39;s dead. I know that I wish that more horror movie people did that but Sartain stops him and he announces and he what he does is he goes over and checks and he announces that Michael is dead. But then he&#39;s got this he&#39;s also throughout the movie.</p>
<p>Dave! (01:27:07.158)
He&#39;s not just some dude.</p>
<p>Dave! (01:27:20.846)
Fair.</p>
<p>Dave! (01:27:32.47)
with the most elaborate weapon I&#39;ve ever seen.</p>
<p>Bryan! (01:27:35.201)
I know we&#39;ve been seeing it and we&#39;ve been led to believe that it&#39;s a pen but it&#39;s actually this like what yeah what do I got I got a little thing oh I say I call it a weird stabby pen thing in my notes because like yeah</p>
<p>Dave! (01:27:42.139)
Mini Harpoon?</p>
<p>Dave! (01:27:50.277)
It looks like a weird tiny whale harpoon.</p>
<p>Bryan! (01:27:53.213)
Yeah, it does. It&#39;s like a scalpel on a on an yeah. Yep. And so he announces, oh, going back. So Michael laid up Hawkins&#39;s fate is unknown. Sartain takes the mask and wears it. And then he goes back to the car for Allison.</p>
<p>Dave! (01:27:56.918)
But with a hook. It has like a hooked end.</p>
<p>Dave! (01:28:11.19)
But for why? Well, it&#39;s like, is he just trying it on? Because then he, he puts it, he like drags the body over and stuffs Michael into the car and then just tosses it. It&#39;s like, so you just want to put it on for like a second?</p>
<p>Bryan! (01:28:15.253)
Yeah, he wants to like, n-</p>
<p>Bryan! (01:28:23.689)
Yeah, you wanted to know what it feels like to be Michael.</p>
<p>Dave! (01:28:25.814)
I think it would have been better if he just drove the car with it on.</p>
<p>Bryan! (01:28:31.237)
Yeah. So, so he&#39;s got Michael in the back with the mask and Allison and that he explains that he intends to bring him to Lori. So now near Lori&#39;s house, Allison fakes Sartain into stopping the car like she says that when that brief moment that she faced him that Michael spoke to her. He didn&#39;t but.</p>
<p>Sartain wants to know what he said and then while this is all happening, Michael comes back to life basically. He puts the mask on and then he kicks the fucking fence in from behind because it&#39;s one of those police cars with the mesh.</p>
<p>Dave! (01:29:08.79)
We finally get a satisfying kill.</p>
<p>Bryan! (01:29:11.245)
And yeah, and he so sartain is beat the fuck he gets out and he takes them. And then he stomps his fucking head in into pieces. And it&#39;s another really great effect. Super gross. But in one of the movie&#39;s most tedious fucking scenes, there are some cops stationed outside the house. This scene is what there is a much longer version of this scene in the deleted scenes.</p>
<p>Dave! (01:29:31.946)
What is the point of this scene?</p>
<p>Dave! (01:29:37.73)
You know what this feels like? Some Tarantino bullshit where it&#39;s like, you think this is funny or clever or cute and it&#39;s none of those things. It&#39;s just feels like space filler.</p>
<p>Bryan! (01:29:45.005)
Yeah, it&#39;s yeah, it really is the longer version is somehow even worse than the one. It&#39;s basically the two of them talking about a banh mi sandwich. But they see the they see Hawkins&#39;s car, the car that just pulled up with Allison and Sartain and all that. So they go up to investigate. And this is when the shape takes the opportunity to kill the shit out of them and steal their car.</p>
<p>and uses it to lure Ray out of the house. So with Ray distracted by the dead bodies of the cops, one of whom has been decapitated, and his head is carved into the likeness of a pumpkin, which is also pretty cool. Lauren hears raise or Laurie rather, here&#39;s raise one gunshot and goes to see what the deal is. And she finds the shape in her front yard just sitting there.</p>
<p>Dave! (01:30:28.905)
And there.</p>
<p>Bryan! (01:30:42.153)
and menacingly in a scene that I.</p>
<p>Dave! (01:30:44.078)
It would have been great if he actually was sitting.</p>
<p>Bryan! (01:30:46.634)
It&#39;s just chillin.</p>
<p>Dave! (01:30:48.142)
Just like, you know, Indian style on the ground. Like, what a night it has been.</p>
<p>Bryan! (01:30:50.509)
the door. He&#39;s just he&#39;s like, Uh got stabbed. Got hit by a lose again. She loses sight of the door and he does when he that are adjacent to the door. like a <em>**</em> impenetrable</p>
<p>Dave! (01:31:02.867)
Even super villains need to rest once in a while.</p>
<p>Dave! (01:31:17.502)
Yeah, I have a lot of problems with the house in general. My first question is, what does Laurie Strode do for a living? Because first of all, she does not strike one as the kind who could hold down a full time job. But also these retrofits had to be expensive. You can&#39;t just be putting in a fucking panic room in your basement with a weird sliding door.</p>
<p>Bryan! (01:31:30.046)
Yeah.</p>
<p>Bryan! (01:31:33.757)
Oh yeah, but she&#39;s</p>
<p>She&#39;s had 40 years. She&#39;s had 40 years to do it though.</p>
<p>Dave! (01:31:40.946)
I hope there was a big lawsuit after that first one.</p>
<p>Bryan! (01:31:45.5)
you know, you can you can build the panic fortress of your dreams.</p>
<p>Dave! (01:31:49.11)
But yeah, there&#39;s a lot of weak points in her house where it&#39;s like, if you&#39;re actually a survivalist or like a paranoid person, you wouldn&#39;t just build a room in your basement with one entrance and exit.</p>
<p>Bryan! (01:31:57.047)
You could-</p>
<p>Bryan! (01:32:03.648)
like, oh my god but that&#39;s the Yeah. But like but to your upstairs balcony door shouldn&#39;t</p>
<p>Dave! (01:32:04.586)
That&#39;s true.</p>
<p>It&#39;s a trap.</p>
<p>Dave! (01:32:11.69)
Nope.</p>
<p>Bryan! (01:32:14.945)
So, yeah. So, eventually, like, they, she, she gets the better. Also, this is the point where Denise was like, he has smashed her face into the door, like, several times. Like, why isn&#39;t she bleeding? I&#39;m like, well, he got shot. So, maybe she&#39;s also like a superhero, but they struggle. She kind of fights him off and then blasts half his hand off with a shotgun. He, I&#39;m going to. The shape, obviously, is unfazed by this.</p>
<p>Dave! (01:32:21.568)
Yeah.</p>
<p>Dave! (01:32:34.283)
And it doesn&#39;t seem to faze him much, nor does it seem to bleed very much.</p>
<p>Bryan! (01:32:41.777)
Laurie retreats to the panic room. Shape lets himself into the house. We can hear him walking around upstairs.</p>
<p>Dave! (01:32:45.474)
Where it&#39;s like, obviously, you guys just get in the fucking panic room and close the thing, he&#39;ll never know you&#39;re down there.</p>
<p>Bryan! (01:32:51.893)
I know. I know. She kind of lets them know that they&#39;re that they&#39;re down there. But for sure. But this is the point where I like this is where the movie is at its strongest. Like the last 30 minutes of this movie are tense and tight.</p>
<p>Dave! (01:33:05.918)
I will say it was, I struggled to pay attention for the entire thing, but I finally was able to like, all right, the action&#39;s kind of picking up a little.</p>
<p>Bryan! (01:33:15.637)
Yeah. So, yeah, Michael&#39;s whereabouts unknown, Laurie emerges into the house to find him. And so in a reversal of like the classic closet scene from the first movie, Laurie, because now she&#39;s the hunter, you know, she&#39;s looking for him. She thinks that Michael is. I thought that would have been funny. Yep. And she goes from room to room.</p>
<p>Dave! (01:33:32.394)
I thought it would have been real cute if he was in the closet. Just sitting there, sitting there, again sitting.</p>
<p>Bryan! (01:33:41.369)
and it&#39;s revealed that she has a cage system installed on the door of every room, and either trap him or limit his options of where to go. Also, what is that like dollhouse thing? Is that the Meyers house?</p>
<p>Dave! (01:33:54.29)
That again feels, yes, I think so, but that is another one where it feels like this was supposed to go somewhere and it doesn&#39;t, because it&#39;s like a diorama or like a dollhouse version of the of the Meyers house. But it&#39;s very specific. They focus on it very intentionally, but then they never come back to it.</p>
<p>Bryan! (01:34:00.99)
Yeah.</p>
<p>Bryan! (01:34:12.853)
Right. Well, they do in a sort of dramatic slow-mo on fire scene.</p>
<p>Dave! (01:34:15.75)
Oh yeah, in a very like TV, made for TV film kind of way.</p>
<p>Bryan! (01:34:20.601)
It&#39;s like a made for TV Amityville movie. So, yeah, now in a let&#39;s see. Spotting bloody handprints. She follows them upstairs into a room like something out of Maniac. It&#39;s</p>
<p>Dave! (01:34:35.222)
Which is another, this is another callback moment. Yeah, that&#39;s when she opens, not the mannequins, when she opens, when she finds already the strongest man in the world in the, jammed into the thing, that&#39;s another callback.</p>
<p>Bryan! (01:34:38.461)
Is it? To a room full of mannequins?</p>
<p>Bryan! (01:34:45.101)
Oh, yeah. Yeah. Oh, right. Because that&#39;s also kind of his thing is the sort of tableau and the arranging of the of the bodies. While she&#39;s up there, the two, he attacks, the shape attacks and the two of them fight. And so in another callback, he like shoves her out the balcony window where she falls to the ground. And so now, Allison has found her way to the house and she calls out inside which distracts him.</p>
<p>Dave! (01:35:14.702)
She&#39;s outside in the mannequin hellscape.</p>
<p>Bryan! (01:35:17.109)
That&#39;s right. It&#39;s a real nightmare out there while he&#39;s not looking.</p>
<p>Dave! (01:35:19.862)
There is that moment where they&#39;re like spinning the camera around and she&#39;s like, it&#39;s like, like each one is like a gargoyle that she keeps like, keep focusing on and she screams and it&#39;s like, it&#39;s just a mannequin. Like, I get it. It&#39;s weird, but. Clearly.</p>
<p>Bryan! (01:35:35.245)
they&#39;re all like riddled with bullet holes and <em>**</em> Yeah, but while anyways, while he&#39;s not looking, Laurie escapes and so now he helps himself to a fire poker as a new weapon and he discovers the panic room and he literally so what this is is it&#39;s it&#39;s a a hatch in the floor in their kitchen which is like hidden by like a like a like a kitchen island.</p>
<p>And so he comes in and literally just tears it off of its hinge. And Karen, seeing no option, takes a rifle bearing her own initials. The one from her childhood. And she readies herself and it seems like she&#39;s frightened, but it&#39;s all a ruse to lure the shape into the opening. And she shoots him in the neck. And then Lori beats the shit out of him with an iron skillet. And then the ladies.</p>
<p>Dave! (01:36:11.01)
Oh, not to- yeah, not just any rifle. Ugh.</p>
<p>Dave! (01:36:27.254)
I&#39;ll say, as plans go to lure him in, this is a pretty shitty plan, because it takes some serious wrangling to get him down there.</p>
<p>Bryan! (01:36:34.349)
get it. They get them down there and then they have to get out of there and like of course this is like the big moment where uh I believe it&#39;s Karen who gets grabbed by him and I think Allison like stabs him a bunch of times and like finally he like lets go and they pull these levers and these <em>**</em> huge iron bar spikes like shoot across the opening trapping him.</p>
<p>And I fucking love the way that they all sort of like stare at each other for a moment because he just stands there on the steps like calmly looking at them.</p>
<p>Dave! (01:37:04.346)
Oh, I don&#39;t. This is another one of those moments where it&#39;s like, bitch, shoot him in the face.</p>
<p>Bryan! (01:37:08.793)
I know, but they set him on fire. But they should have shot him in the face because that would have that would have made sure because if the next fucking movie is has anything to say about it, that fire is not going to stop him.</p>
<p>Dave! (01:37:11.982)
Okay. Yeah.</p>
<p>Dave! (01:37:20.732)
if little Tommy Jarvis cannot be the only one who knows how to kill a supervillain.</p>
<p>Bryan! (01:37:25.353)
Yeah. But I do I do love that the way that they stop and he&#39;s just like fucking staring up at them. It&#39;s it&#39;s a very, very cool scene. And then, yeah, but anyways, the place they like throw a bunch of dials and the place starts to fill with gas and then Laurie, you know, sets the place on fire while Michael calmly watches them. And then the entire house goes up in flame.</p>
<p>and they go out into the they go outside and they flag down a passing truck and they&#39;re rescued. And Michael meanwhile, we get more shots of like the basement on fire and stuff and Michael is nowhere to be seen.</p>
<p>And then the whole house goes up. Roll credits.</p>
<p>Bryan! (01:38:12.617)
Yeah, obviously I liked it more than you did.</p>
<p>Dave! (01:38:15.53)
Look, there are parts of it I thought were OK. I didn&#39;t hate it. I just it feels like they were trying to do too many things and none of them really work very well.</p>
<p>Bryan! (01:38:23.881)
It&#39;s a bit it&#39;s a bit it&#39;s a bit messy and I</p>
<p>Dave! (01:38:25.778)
And there&#39;s so much like referencing the other movie where it&#39;s like, Jesus Christ, did you want to make your own movie or not?</p>
<p>Bryan! (01:38:31.561)
Yeah. Some of the things I&#39;d read about it was like we wanted to sort of dismiss all these other parts of the movies without upsetting the fans and so maybe they felt like they needed to throw some fan service into it to be like, haha, like we know what we&#39;re doing, right? I don&#39;t mind it. You know, I am also like unabashedly a fan of fan service, you know?</p>
<p>Dave! (01:38:44.467)
Some?</p>
<p>Dave! (01:38:54.674)
I like it when it&#39;s done well. I feel like some of it was and some of it wasn&#39;t. Some of it was real in your face and this shit. Like, why don&#39;t, you know, they may as well just had him do that fucking cocked head look at absolutely everything that he looks at. I know, it&#39;s exhausting.</p>
<p>Bryan! (01:39:06.069)
he does it a couple of times, doesn&#39;t he? Yeah. But that&#39;s uh that&#39;s it. That&#39;s David Gordon Green&#39;s 2018 Halloween. And uh I don&#39;t I I&#39;m not I&#39;m not nearly as kind to those as uh as I am to this one. Like I think that this one succeeded like you know terms of like budget you</p>
<p>Dave! (01:39:15.25)
And I hope we never talk about the other ones. Because those movies really suck.</p>
<p>Bryan! (01:39:36.493)
fans as well. People like this movie. You know, the those sequels are a little ridiculous.</p>
<p>Dave! (01:39:42.67)
I wish they would have said, instead of saying, what movie do you want to make? And they say, Halloween. I wish they had just said a horror movie. They could have made a great horror movie. They chose to do this, which I feel like is a losing game no matter what you do. Like you should have just made a good fucking horror movie because clearly they can. He&#39;s a good director. They&#39;re decent writers. I don&#39;t think this is a well-written movie, but they are capable writers.</p>
<p>Bryan! (01:39:59.582)
Yeah.</p>
<p>Bryan! (01:40:04.34)
Mm.</p>
<p>Dave! (01:40:09.578)
Like they have what it takes, you just chose the wrong thing.</p>
<p>Bryan! (01:40:09.663)
Yeah.</p>
<p>Bryan! (01:40:13.161)
few more drafts of the script. I think it could have been tightened up. But also, I think that there was some pressure to deliver something for the 40th anniversary, and also sort of not fall victim to the same sort of problem that the last like all the other sort of pitched scripts fell into. But you know,</p>
<p>Dave! (01:40:31.658)
I think there&#39;s also an element of taking themselves too seriously.</p>
<p>Bryan! (01:40:36.921)
You think? Because there&#39;s a lot of really silly, stupid comedy moments in this.</p>
<p>Dave! (01:40:39.242)
I think there&#39;s some goofy shit, but I think that for the most part, they thought they were doing something that I don&#39;t think they were.</p>
<p>Bryan! (01:40:46.317)
Oh, yeah. I think in the same way that Rob Zombie wanted to make his own version of Halloween, like these guys definitely could not resist the fucking urge to do the same thing. Because it was probably like the way that like Kevin Smith talks about like after chasing Amy kind of broke, and he went to like one of the major studios and they said, here&#39;s like five movies, which of these ones do you want to do? And there was like a Halloween on the on the list. And they were like, we&#39;re doing fucking Halloween.</p>
<p>Bryan! (01:41:13.557)
but yeah, so we&#39;re doing Halloween again next week. Yep.</p>
<p>Dave! (01:41:19.37)
Yeah, we sure are. We&#39;re doing Rob Zombie&#39;s Halloween. And I&#39;ll tell you what, as uncharitable as I was with this one, that is about how charitable I&#39;m going to be with the next one, because I think that movie is absolute horse shit. But God bless him for making it.</p>
<p>Bryan! (01:41:22.686)
Rob Zombie&#39;s Halloween.</p>
<p>Bryan! (01:41:29.717)
I think we&#39;re gonna be.</p>
<p>Bryan! (01:41:35.817)
I think we&#39;re of a mind on that one. Like we&#39;re probably gonna see eye to eye on this one cause I have not, I saw that movie when it came out and I have not gone back to that well. And I.</p>
<p>Dave! (01:41:43.83)
Nope. Do I like anything Rob Zombie has ever done outside of White Zombie? No, I do not. I absolutely do not. I think he&#39;s not good at things, but I just love that he&#39;s out there making shit.</p>
<p>Bryan! (01:41:54.497)
He&#39;s he&#39;s living the dream. He&#39;s doing basically what I would do if I wielded tremendous amounts of influence and money like hey.</p>
<p>Dave! (01:42:02.214)
I think he is actually a pretty good director. I just think he&#39;s not a good writer.</p>
<p>Bryan! (01:42:06.409)
No, no. But yeah, I mean, that&#39;s exactly what I would do if somebody was like, hey, here&#39;s like limitless amounts of cash. What do you want to do with it? And I&#39;d be like, well, I really want to make horror movies. And so they&#39;d be like, fine.</p>
<p>Dave! (01:42:19.69)
Yeah, it&#39;s the same as what we said about Ruben Galindo Jr. It&#39;s like, you may not be really great at this, but the fact that you love this so much comes through to the point that I now love what you&#39;re doing.</p>
<p>Bryan! (01:42:32.097)
it&#39;s a weird relationship with his movies. I do actually, well, we&#39;ll get to it. I do actually, there is one of his movies that I do actually like a good deal, but we&#39;ll, Lord&#39;s a Salem, we&#39;ll talk about it when we get there. Yeah. But uh, yep. So yeah, we&#39;ll see you in two weeks, two weeks with uh, Halloween.</p>
<p>Dave! (01:42:39.274)
You talking about Lord Sassailin? That&#39;s the closest he came.</p>
<p>Dave! (01:42:48.672)
oooey</p>
<p>Yep.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/friday-the-13th">Next Episode</Link></li>
                        <li><Link to="/episodes/dont-panic">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)